<template>

  <div class="offers">

    <link-header :style="{ backgroundImage: 'url('+ require(`../../images/background.jpg`)+')'}">

      <h1 class="header-text header-text--left">
        L'offre de LINK by Primonial répond précisément à vos objectifs :

        <div class="text-with-choices">
          <div class="text-with-choices-item">
            <p>Je souhaite investir</p>
            <input type="text" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                   v-model.number="$root.euros" maxlength="10">
            <p>€,</p>
          </div>
          <div class="text-with-choices-item">
            <p>avec pour objectif</p>
            <select name="" id="" v-model="$root.objectif">
              <option :disabled="item.value === null" v-for="item in objectifs" :value="item.value">{{item.text }}
              </option>
            </select>
            <p>.</p>
          </div>
          <button :class="{'active' : $root.euros && $root.objectif}" class="header-button"
                  @click="scrollToOffers()">Voir les offres
          </button>
        </div>

      </h1>

      <template v-slot:deco>
        <div class="header-deco"></div>
      </template>

    </link-header>

    <div class="offers-background">

      <div class="container">

        <breadcrumb :items="[{text: 'Accueil', url: '/'}, { text: 'Nos offres'} ]"></breadcrumb>

        <h2 class="page-title">Les solutions d'épargne qui correspondent à mes objectifs</h2>


        <div class="product">

          <div class="product-item" v-for="(item, index) in products"
               :class="{'last' : index === $root.products.length - 1}">
            <div class="product-item-row">
              <aside class="product-aside">
                <div class="product-image"
                     :style="{ backgroundImage: item.vignette ? `url(/storage/${item.vignette})` : 'none'}"></div>
                <div v-if="JSON.parse(item.profiles)[profil]">
                  <div class="product-aside-title">Exemple de profil</div>
                  <div class="product-aside-profil"><span></span> {{ JSON.parse(item.profiles)[profil].profil }}
                  </div>
                </div>
                <div v-if="JSON.parse(item.profiles)[profil]">
                  <div class="product-aside-title">Performances <span v-html="getDateFromString(JSON.parse(item.profiles)[profil].date, 'year')"></span></div>
                  <div class="product-aside-performances">
                    <span class="product-aside-performances--icon"></span>
                    {{ JSON.parse(item.profiles)[profil].perf }} %
                    <span class="product-aside-performances--date" style="text-transform: lowercase">{{ JSON.parse(item.profiles)[profil].date }}</span>
                  </div>
                </div>

                <div v-if="JSON.parse(item.profiles)[profil] && JSON.parse(item.profiles)[profil].datem">
                  <div class="product-aside-title">Performances <span v-html="getDateFromString(JSON.parse(item.profiles)[profil].datem, 'year')"></span></div>
                  <div class="product-aside-performances">
                    <span class="product-aside-performances--icon"></span>
                    {{ JSON.parse(item.profiles)[profil].perfm }} %
                  </div>
                </div>

                <router-link v-if="item.simulateur" to="/simulation"
                             class="button product-simulate-button">Je simule mon
                  épargne
                </router-link>
              </aside>
              <section class="product-section">
                <h3 class="product-title" v-html="item.title"></h3>
                <p class="product-introduction" v-html="item.product_introduction"></p>

                <div class="product-keys">
                  <div class="product-keys-title">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.35 81.4">
                      <path
                          d="M80,73.7,65.4,59.2a36.66,36.66,0,1,0-28.8,14,36.17,36.17,0,0,0,22.5-7.8L73.6,80.1a4.38,4.38,0,0,0,3.2,1.3A4.82,4.82,0,0,0,80,80.1,4.47,4.47,0,0,0,80,73.7ZM64.2,36.6A27.6,27.6,0,0,1,9,36.6,27.58,27.58,0,0,1,56.1,17.1,27.38,27.38,0,0,1,64.2,36.6Z"/>
                    </svg>
                    Points clés
                  </div>
                  <div class="product-keys-content" v-html="item.keys"></div>
                </div>

                <router-link :to="`/nos-offres/${item.slug}`" class="button product-button">Je découvre
                </router-link>


              </section>
            </div>
            <p class="warning">
              <span></span>
              <span>Les performances annoncées sont nettes de frais de gestion du mandat et du contrat, hors fiscalité applicable. L’allocation en unités de compte<sup>(1)</sup> au sein de votre contrat fait
                        l’objet d’arbitrages réalisés par Oradéa Vie sur la base des conseils de Lyxor AM, en fonction de
                        l’évolution de l’environnement économique et financier et du profil choisi. <strong>Les investissements en
                        unités de compte présentent un risque de perte en capital. Les performances passées ne présagent pas
                            des performances futures. La définition de votre profil de risque est réalisée dans le cadre de la souscription au contrat, la performance du profil {{
                    JSON.parse(item.profiles)[profil].profil
                  }} est donnée à titre illustratif</strong>
                                <span>
                                    <sup>(1)</sup> l’assureur ne s’engage que sur le nombre d’unités de compte et non sur leur valeur. La valeur des unités de compte qui reflète la valeur d’actifs sous-jacents, n’est pas garantie mais est sujette à des fluctuations à la hausse comme à la baisse dépendant en particulier de l’évolution des marchés financiers. Le fonctionnement des unités de compte est décrit dans la Note d’Information du contrat.</span>
                    </span>
            </p>

          </div>

        </div>

      </div>
    </div>

    <div class="container">
      <div class="offers-blocks">
        <div class="offers-blocks-item offers-blocks-item-secondary">
          <p>Vous voulez en savoir plus<br> sur les avantages de l'épargne<br> LINK by Primonial ?</p>
          <router-link to="/les-avantages" class="button">Plus d'informations sur l'offre</router-link>
        </div>
        <div class="offers-blocks-item">
          <p>Vous avez<br> des questions ?</p>
          <a href="mailto:hotline@linkbyprimonial.fr" class="button">Contacter un parrain financier</a>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

//  import noUiSlider from 'nouislider'
// import 'nouislider/distribute/nouislider.min.css'

export default {
  name: "Offers",
  data() {
    return {
      profil: 0,
      objectifs: [
        {text: '', value: null},
        {text: 'de constituer un capital', value: 'capital'},
        {text: 'de dynamiser mon épargne', value: 'dynamiser'},
        {text: `d'épargner pour mes enfants`, value: 'epargne'},
        {text: 'de préparer l\'avenir de mes enfants', value: 'avenir'},
        {text: 'd\'investir en ETF', value: 'investir'},
      ],
    }
  },
  computed: {
    settings() {
      return this.$root.settings;
    },
    products() {
      if (!this.$root.objectif)
        return this.$root.products;


      return this.$root.products.filter(el => {
        if (el.filters !== null) {
          if (el.filters.includes(this.$root.objectif))
            return true;
        }
      })

    },
  },
  watch: {
    settings() {
      document.title = this.settings && this.settings.site_title ? `Nos offres | ${this.settings.site_title}` : 'Nos offres'
    }
  },
  mounted() {
    /*
                let range = document.querySelector('#range')
                if (range) {
                    noUiSlider.create(range, {
                        range: {
                            min: 100,
                            max: 100000
                        },
                        start: [100, 100000],
                    })
                }
    */

    if (!this.$root.products.length) {
      axios.get('/api/products')
          .then(res => {
            this.$root.products = res.data;

            this.profil = Math.floor(Math.random() * JSON.parse(this.products[0].profiles).length)
          })
    }
  },
  methods: {
    scrollToOffers() {

      let header = document.querySelector('.header').clientHeight;

      window.scroll({
        top: header,
        behavior: 'smooth'
      });

    },
    getYearFromDate(date) {
      //console.log(new Date(date));

      return new Date(date).getFullYear();
    },
    getDateFromString(string, options) {

      let day, month, year, dateSplitted, aux, result;

      result = string.match("[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}");

      if(null != result) {
        dateSplitted = result[0].split(result[1]);
        day = dateSplitted[0];
        month = dateSplitted[1];
        year = dateSplitted[2];
      }
      /*
            result = string.match("[0-9]{4}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{2}");

            if(null != result) {
              dateSplitted = result[0].split(result[1]);
              day = dateSplitted[2];
              month = dateSplitted[1];
              year = dateSplitted[0];
            }

       */

      if(null === result) {
        day = new Date().getDate();
        day = ("0" + day).slice(-2);
        month = new Date().getMonth() + 1;
        month = ("0" + month).slice(-2);

        return `${day}.${month}.${new Date().getFullYear()}`
      }

      if(month>12) {
        aux = day;
        day = month;
        month = aux;
      }

      // year = year.length === 4 ? year.substr(2,2) : year;

      if(options && options === 'year')
        return year;

      return `${day}.${month}.${year}`;
    },
  }
}
</script>
