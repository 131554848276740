<template>

    <div class="faq">

        <template>
            <link-header>
                <h1 class="header-text">F.A.Q</h1>
            </link-header>
            <div class="container">

                <breadcrumb
                        :items="[{text: 'Accueil', url: '/'}, {text: 'F.A.Q'} ]"></breadcrumb>
            </div>
        </template>

        <div class="container">
            <h2 class="faq-title">Le concept</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option1')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
            <h2 class="faq-title">Le contrat</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option2')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
            <h2 class="faq-title">Le robot</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option3')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
            <h2 class="faq-title">Le parrain</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option4')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
            <h2 class="faq-title">Le processus de souscription</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option5')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
            <h2 class="faq-title">Les intervenants</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option6')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
            <h2 class="faq-title">Généralités de l’assurance vie</h2>
            <div class="faq-item" v-for="item in faqs.filter(el => el.category === 'option7')">
                <h3 class="faq-subtitle" @click="toggle($event)">
                    {{ item.question }}
                    <div class="faq-button">
                        <span></span>
                        <span></span>
                    </div>
                </h3>
                <div class="faq-content" v-html="item.reponse"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FAQ",
        data() {
            return {
                faqs: null,
            }
        },
        created() {

            axios.get('/api/faqs')
                .then(res => {
                    this.faqs = res.data;
                })

        },
        methods: {
            toggle(event) {
                event.target.classList.toggle('active');
            }
        }
    }
</script>

<style lang="scss">

    .faq {
        .container {
            margin-top: 0px;
            display: flex;
            flex-direction: column;
        }

        &-title {
            color: #6dbbc7;
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 35px;
            margin-top: 80px;
        }

        &-subtitle {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 900;
            color: #505050;
            font-size: 20px;
            border-bottom: 2px solid #dbe6ed;
            padding-bottom: 5px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            user-select: none;

            @media screen and (max-width: 600px) {
                font-size: 14px;
            }


            &.active {

                + .faq-content {
                    display: block;
                }

            }

        }

        &-button {
            width: 12px;
            height: 12px;
            position: relative;
            min-width: 12px;
            margin-left: 12px;

            span {
                position: absolute;
                background: #6dbbc7;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &:first-child {
                    width: 100%;
                    height: 2px;
                }

                &:last-child {
                    width: 2px;
                    height: 100%;
                }

            }
        }

        &-item {
            margin-bottom: 40px;
        }

        &-content {
            display: none;

            p {
                font-size: 18px;
                letter-spacing: .025em;
                color: #505050;
                line-height: 29px;
                margin-bottom: 20px;

                @media screen and (max-width: 600px) {
                    font-size: 14px;
                    line-height: 24px;
                }

                &.faq-mentions {
                    font-size: 13px;
                    line-height: 20px;
                }

                &.faq-body {
                    font-size: 16px;
                    line-height: 17px;
                }

            }

            ul {
                margin-bottom: 40px;

                li {
                    font-size: 18px;
                    letter-spacing: .025em;
                    color: #505050;
                    line-height: 29px;

                    @media screen and (max-width: 600px) {
                        font-size: 14px;
                        line-height: 24px;
                    }

                    span {

                    }

                    ul {
                        margin-bottom: 0;

                        li {
                            font-size: 16px;

                            @media screen and (max-width: 600px) {
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
