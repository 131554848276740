<template>

    <div class="points" :class="{ 'points--reverse' : reverse !== undefined }">
        <div class="points-container">
            <div class="points-icon"
                 :style="{ backgroundImage : `url(/storage/${icon})` }"></div>
            <div class="points-content">
                <h4 class="points-title" v-html="title"></h4>
                <div class="points-body" v-html="body"></div>
                <router-link :to="`/les-avantages#${hash}`" class="button points-button">En savoir plus</router-link>
            </div>
        </div>
        <div class="points-image">
            <img :src="`/storage/${image}`" alt="">
        </div>
    </div>

</template>

<script>

    import slugify from 'slugify';

    export default {
        name: "Points",
        props: ['reverse', 'icon', 'title', 'body', 'image'],
        computed: {
            hash() {
                return slugify(this.title.toLowerCase())
            }
        }
    }
</script>
