<template>


    <div class="ratings">
        <svg class="star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.18 24.9" v-for="n in max">
            <path :class="{ 'active' : n <= score}" class="star_path"
                  d="M13.09,1.15l3.67,7.56,8.33,1.16L19,15.69,20.51,24l-7.42-4L5.67,24l1.48-8.28L1.09,9.87,9.42,8.71Z"/>
        </svg>

    </div>


</template>

<script>
    export default {
        name: "Rating",
        props: ['max', 'score'],
    }
</script>

<style scoped lang="scss">

    $yellow: #ffea27;

    .ratings {
        display: flex;
    }

    .star {
        width: 30px;
        height: 30px;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }

    .star_path {
        fill: white;
        stroke: $yellow;
        stroke-miterlimit: 10;

        &.active {
            fill: $yellow;
        }
    }

</style>
