<template>

    <nav class="navigation" :class="{ 'sticky' : sticky }">


        <div class="navigation-item navigation-item--logo" :class="{ 'active' : sticky }" @click="homepage()">
            <div>
                Accueil
            </div>
        </div>

        <a href="" target="_blank" @click.prevent="$root.subscribe"
           class="navigation-item navigation-item--subscribe">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.8 95">
                <path d="M80.8,56.1a7.94,7.94,0,0,0-8-7.9,7.4,7.4,0,0,0-3.6.9,7.82,7.82,0,0,0-6.9-4,7.4,7.4,0,0,0-3.6.9,7.82,7.82,0,0,0-6.9-4,7.46,7.46,0,0,0-2.6.4V37.8h6a8.62,8.62,0,0,0,8.6-8.7V8.7A8.69,8.69,0,0,0,55.2,0H8.6A8.62,8.62,0,0,0,0,8.7V29a8.69,8.69,0,0,0,8.6,8.7H33.2V61.5l-3.3-3.3a8.16,8.16,0,0,0-10.2-1,8.43,8.43,0,0,0-3.5,5.5A8.12,8.12,0,0,0,18,69L32.4,86.4A23.49,23.49,0,0,0,50.6,95h9A21.17,21.17,0,0,0,80.7,73.9Zm-21,33.5h-9A18.24,18.24,0,0,1,36.7,83L22.3,65.6a2.63,2.63,0,0,1-.6-2.1,2.45,2.45,0,0,1,1.1-1.8,2.69,2.69,0,0,1,1.5-.5,2.73,2.73,0,0,1,1.9.8L32,67.8a4,4,0,0,0,4.3.8A3.8,3.8,0,0,0,38.7,65V27.6A2.65,2.65,0,0,1,41.3,25a2.74,2.74,0,0,1,2.6,2.6V53.3a2.7,2.7,0,0,0,5.4,0V49.8a2.6,2.6,0,0,1,5.2,0v6.7a2.7,2.7,0,1,0,5.4,0V53a2.6,2.6,0,1,1,5.2,0v6.7a2.7,2.7,0,1,0,5.4,0V56.2a2.6,2.6,0,0,1,5.2,0V74a15.5,15.5,0,0,1-4.6,11.1A16.85,16.85,0,0,1,59.8,89.6ZM8.6,31.7A2.61,2.61,0,0,1,6,29V8.7A2.67,2.67,0,0,1,8.6,6H55.1a2.61,2.61,0,0,1,2.6,2.7V29a2.67,2.67,0,0,1-2.6,2.7h-6v-4a7.9,7.9,0,1,0-15.8.1v4H8.6Z"/>
                <path d="M25.9,13l-5.5,5.9-1.9-2.1a2.69,2.69,0,0,0-3.9,3.7l3.9,4.2a2.67,2.67,0,0,0,4,0l7.5-8a2.69,2.69,0,0,0-.1-3.8A2.93,2.93,0,0,0,25.9,13Z"/>
            </svg>
            <div>
                Je souscris
            </div>
        </a>
        <a href="" @click.prevent="$root.login" target="_blank"
           class="navigation-item navigation-item--edit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M498.8,161.1a45.09,45.09,0,0,0-63.6,0c-5.4,5.4-39.9,39.9-45.1,45.2V87.4a44.71,44.71,0,0,0-13.2-31.8L334.5,13.2A45.12,45.12,0,0,0,302.6,0H45A45.07,45.07,0,0,0,0,45V467a45.07,45.07,0,0,0,45,45H345a45.07,45.07,0,0,0,45-45V333.6L498.8,224.7A44.9,44.9,0,0,0,498.8,161.1ZM300,30c2.8,0,8.3-.5,13.2,4.4l42.4,42.4c4.8,4.8,4.4,10,4.4,13.2H300Zm60,437a15,15,0,0,1-15,15H45a15,15,0,0,1-15-15V45A15,15,0,0,1,45,30H270v75a15,15,0,0,0,15,15h75V236.3l-44.3,44.3-21.2,21.2a14.94,14.94,0,0,0-3.6,5.9l-21.2,63.6a15,15,0,0,0,19,19l63.6-21.2a15.67,15.67,0,0,0,5.9-3.6l1.8-1.8V467ZM326.4,312.4l21.2,21.2-8.1,8.1-31.8,10.6,10.6-31.8Zm42.4,0-21.2-21.2c11.3-11.3,61.5-61.5,72.1-72.2l21.2,21.2ZM477.6,203.6,462.1,219l-21.2-21.2,15.5-15.5a15,15,0,1,1,21.2,21.3Z"/>
                <path d="M285,150H75a15,15,0,0,0,0,30H285a15,15,0,0,0,0-30Z"/>
                <path d="M225,210H75a15,15,0,0,0,0,30H225a15,15,0,0,0,0-30Z"/>
                <path d="M225,270H75a15,15,0,0,0,0,30H225a15,15,0,0,0,0-30Z"/>
                <path d="M225,330H75a15,15,0,0,0,0,30H225a15,15,0,0,0,0-30Z"/>
                <path d="M285,422H195a15,15,0,0,0,0,30h90a15,15,0,0,0,0-30Z"/>
            </svg>
            <div>
                Gérer mes souscriptions
            </div>
        </a>
        <a v-if="$root.settings && $root.settings.space_client" :href="$root.settings.space_client" target="_blank"
           class="navigation-item navigation-item--client">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14">
                <path d="M12.5,12a.5.5,0,0,1-.5-.5V1H4v.5a.5.5,0,0,1-1,0V.5A.5.5,0,0,1,3.5,0h9a.5.5,0,0,1,.5.5v11A.5.5,0,0,1,12.5,12Z"/>
                <path d="M8.5,12h-5a.5.5,0,0,1-.5-.5v-2a.5.5,0,0,1,1,0V11H8.5a.5.5,0,0,1,0,1Z"/>
                <path d="M8.5,14a.48.48,0,0,1-.26-.07A.52.52,0,0,1,8,13.5V2.5a.51.51,0,0,1,.28-.45l4-2A.51.51,0,0,1,13,.28a.51.51,0,0,1-.23.67L9,2.81v9.88l3.28-1.64a.5.5,0,0,1,.44.9l-4,2A.54.54,0,0,1,8.5,14Z"/>
                <path d="M6.5,6H.5a.5.5,0,0,1,0-1h6a.5.5,0,0,1,0,1Z"/>
                <path d="M4.5,8a.47.47,0,0,1-.35-.15.48.48,0,0,1,0-.7L5.79,5.5,4.15,3.85a.49.49,0,0,1,.7-.7l2,2a.48.48,0,0,1,0,.7l-2,2A.47.47,0,0,1,4.5,8Z"/>
            </svg>
            <div>
                Espace client
            </div>

        </a>
        <a v-if="$root.settings && $root.settings.twitter_url" :href="$root.settings.twitter_url" target="_blank"
           class="navigation-item navigation-item--tw">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436 354.1">
                <path id="body"
                      d="M110.8,291.1c-1.6,1-3,1.7-4,2.3-3,1.5-7.9,3.8-14.9,6.9a152.38,152.38,0,0,1-23.1,7.9A204.17,204.17,0,0,1,47,312.9c-6.2.9-12.2,1.5-18.1,1.8a154.94,154.94,0,0,1-16.7,0c-5.2-.3-8.5-.5-9.6-.6l-1.8-.2-.4-.1-.4-.1v.8H.2l.1.1.1.1.1.1.1.1.1.1H.9l.4.2.4.2.1.1.1.1.1.1.1.1L5.7,318c2.4,1.5,4.5,2.6,6.4,3.6s6.6,3.3,14.1,7.1a252.74,252.74,0,0,0,27.2,11.1c10.6,3.7,18.6,6.2,24,7.4s12.8,2.6,22.2,3.9,14.9,2.1,16.3,2.2,3.3.3,5.5.4l3.3.2v.2h25.6v-.2l14-1.3c9.3-.9,17.6-2.1,25-3.3a197.49,197.49,0,0,0,22.8-5.5c7.9-2.4,15.3-4.9,22.4-7.7a171.52,171.52,0,0,0,19.7-9c6.1-3.2,11.3-6,15.6-8.5a169.34,169.34,0,0,0,14.2-9.3c5.2-3.7,10-7.5,14.6-11.2s7.1-5.9,7.7-6.4l10.2-10.2c6.2-6.3,11.3-11.9,15.4-16.8s8-10.3,12-15.9,8.3-12.5,13-20.6a243.36,243.36,0,0,0,13.5-27.5,260.65,260.65,0,0,0,11-31.5c3-10.7,5.2-20.4,6.7-28.9s2.4-16.5,3-23.8.8-13.9.9-19.7A18.85,18.85,0,0,1,399.4,82a183,183,0,0,0,15-13.7c5.1-5.1,7.9-8,8.3-8.7a4.41,4.41,0,0,1,1.1-1.2c.3-.1,2.1-2.3,5.3-6.7s5-6.6,5-6.7l.1-.1.2-.4.2-.4.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.2-.1.2-.1V42l-1,.3-.8.1-.4.2-.4.2-.4.2-.4.2-.6.2-1.2.4-10.6,3.6a214.47,214.47,0,0,1-21,5.7l-11,2.4h-1.9l.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.4-.2.4-.2.1-.1.1-.1.1-.1.1-.1.4-.2.4-.2,1.9-1.3a18.67,18.67,0,0,0,3.3-2.8,74.12,74.12,0,0,1,5.6-5.1,67.3,67.3,0,0,0,9.5-10.7,94.75,94.75,0,0,0,8.8-14c2.3-4.6,3.6-7.2,3.7-7.9s.3-1.2.4-1.6l.2-.6.2-.4.2-.4.2-.6.2-.6.1-.6.1-.6-.4.1-.4.1-.1.1-.1.1-.1.1-.1.1-.4.2-.4.2-.4.2-.4.2-.1.1c-.1.1-.8.4-1.9,1.2s-4.7,2.4-10.5,5-11.6,5-17.5,7.1A155.32,155.32,0,0,1,374.2,26c-5.1,1.2-8.8.5-11.3-1.9a64.64,64.64,0,0,0-8.3-6.9,98.49,98.49,0,0,0-10.4-6.4,97.53,97.53,0,0,0-11.8-5.5,71.52,71.52,0,0,0-13.5-3.8L311.5,0H291V.1a34.33,34.33,0,0,1-5.1.9,74.15,74.15,0,0,0-12.6,3.3A107.28,107.28,0,0,0,257,11.7a83.58,83.58,0,0,0-15.7,11.2,87,87,0,0,0-11.2,12.2,112,112,0,0,0-7,10.5C189.4,90.3,199.2,234,110.8,291.1Z"
                      style="fill:#fff"/>
                <path id="wing1"
                      d="M201.1,108.5q-7.5-.75-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5S147,96.7,136.9,93a222.45,222.45,0,0,1-28.3-12.8,262.4,262.4,0,0,1-24-14.4C77.4,60.9,71.5,56.7,67.1,53s-7.1-6-7.9-7a7.1,7.1,0,0,0-1.9-1.8c-.5-.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9-.1-.1-.1-.1L31,17l-.2-.4-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1V16l-.1.1-.1.1v.1l-.1.1-.2.6-.2.6L25.8,25a101.71,101.71,0,0,0-5.1,14.1,101.43,101.43,0,0,0-2.4,12,99.36,99.36,0,0,0-.4,12.8A78.74,78.74,0,0,0,19.7,79a85.43,85.43,0,0,0,4.6,15.2,129.28,129.28,0,0,0,5.8,12.5,57.61,57.61,0,0,0,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3a55.27,55.27,0,0,0,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l.1.1.4.2.4.2.1.1.1.1.1.1.1.1.4.2.4.2.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1H52.9l-1.8-.2c-1.2-.1-4.8-.9-10.8-2.2a77.44,77.44,0,0,1-15.1-4.7l-6.1-2.8-.4-.2-.4-.2-.4-.2-.4-.2-.4-.1-.4-.1.7,8.3a89.63,89.63,0,0,0,2.8,16.3,97,97,0,0,0,6.5,16.9,80.61,80.61,0,0,0,9.8,15.5,115.51,115.51,0,0,0,10.1,11,93.14,93.14,0,0,0,9.8,7.8,77.93,77.93,0,0,0,13.8,7.3c5.8,2.5,9.3,3.9,10.6,4.3s2.2.6,2.8.8l.8.2.8.2.8.2.8.2.8.2-.1.1-.1.1-.1.1-.1.1-1,.2-1,.2-.8.2c-.5.1-1.9.4-4.3.8a119.52,119.52,0,0,1-12.8.8,97.8,97.8,0,0,1-13.5-.4l-4.3-.6-.6-.1-.6-.1.1.4.1.4.2.6.2.6,3.2,7.2a114.06,114.06,0,0,0,6.7,12.7,73.44,73.44,0,0,0,8.3,10.7,100.61,100.61,0,0,0,7.9,8,73.69,73.69,0,0,0,9.9,7.2A95.14,95.14,0,0,0,99,270.1a87.64,87.64,0,0,0,13.5,4.7,82.18,82.18,0,0,0,9.2,1.8c1.9.1,4,.3,6.1.4l3.1.2c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-.1C209.5,109.3,206.1,108.9,201.1,108.5Z"
                      style="fill:#fff"/>
                <path id="wing2"
                      d="M201.1,108.5q-7.5-.75-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5S147,96.7,136.9,93a222.45,222.45,0,0,1-28.3-12.8,262.4,262.4,0,0,1-24-14.4C77.4,60.9,71.5,56.7,67.1,53s-7.1-6-7.9-7a7.1,7.1,0,0,0-1.9-1.8c-.5-.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9-.1-.1-.1-.1L31,17l-.2-.4-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1V16l-.1.1-.1.1v.1l-.1.1-.2.6-.2.6L25.8,25a101.71,101.71,0,0,0-5.1,14.1,101.43,101.43,0,0,0-2.4,12,99.36,99.36,0,0,0-.4,12.8A78.74,78.74,0,0,0,19.7,79a85.43,85.43,0,0,0,4.6,15.2,129.28,129.28,0,0,0,5.8,12.5,57.61,57.61,0,0,0,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3a55.27,55.27,0,0,0,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l.1.1.4.2.4.2.1.1.1.1.1.1.1.1.4.2.4.2.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1H52.9l-1.8-.2c-1.2-.1-4.8-.9-10.8-2.2a77.44,77.44,0,0,1-15.1-4.7l-6.1-2.8-.4-.2-.4-.2-.4-.2-.4-.2-.4-.1-.4-.1.7,8.3a89.63,89.63,0,0,0,2.8,16.3,97,97,0,0,0,6.5,16.9,80.61,80.61,0,0,0,9.8,15.5,115.51,115.51,0,0,0,10.1,11,93.14,93.14,0,0,0,9.8,7.8,77.93,77.93,0,0,0,13.8,7.3c5.8,2.5,9.3,3.9,10.6,4.3s2.2.6,2.8.8l.8.2.8.2.8.2.8.2.8.2-.1.1-.1.1-.1.1-.1.1-1,.2-1,.2-.8.2c-.5.1-1.9.4-4.3.8a119.52,119.52,0,0,1-12.8.8,97.8,97.8,0,0,1-13.5-.4l-4.3-.6-.6-.1-.6-.1.1.4.1.4.2.6.2.6,3.2,7.2a114.06,114.06,0,0,0,6.7,12.7,73.44,73.44,0,0,0,8.3,10.7,100.61,100.61,0,0,0,7.9,8,73.69,73.69,0,0,0,9.9,7.2A95.14,95.14,0,0,0,99,270.1a87.64,87.64,0,0,0,13.5,4.7,82.18,82.18,0,0,0,9.2,1.8c1.9.1,4,.3,6.1.4l3.1.2c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-.1C209.5,109.3,206.1,108.9,201.1,108.5Z"
                      style="fill:#fff"/>
            </svg>
        </a>
        <div class="navigation-item navigation-item--menu" @click="$root.menu = true">
            <svg viewBox="0 0 17 14"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M-0.000,14.000 L-0.000,12.000 L17.001,12.000 L17.001,14.000 L-0.000,14.000 ZM-0.000,6.000 L17.001,6.000 L17.001,8.000 L-0.000,8.000 L-0.000,6.000 ZM-0.000,-0.000 L17.001,-0.000 L17.001,2.000 L-0.000,2.000 L-0.000,-0.000 Z"/>
            </svg>
            Menu
        </div>

    </nav>

</template>

<script>
    export default {
        name: "Navigation",
        data() {
            return {
                sticky: false,
            }
        },
        mounted() {

            window.addEventListener('scroll', () => {

                if (window.pageYOffset > 200)
                    this.sticky = true
                else
                    this.sticky = false

            })

        },
        methods: {
            homepage() {

                if (this.$route.fullPath === '/')
                    window.scroll({
                        top: 0,
                        behavior: 'smooth'
                    });
                else
                    this.$router.push('/');

            }
        }
    }
</script>

<style>

    .navigation-item--tw:hover #wing1,
    .navigation-item--tw:hover #wing2 {
        animation: flap .6s ease-out infinite alternate;
    }

    #wing1 {
        transform-origin: center;
    }

    #wing2 {
        transform-origin: center;
    }

    @keyframes flap {
        50% {
            transform: scaleX(-1) rotate(-45deg) translate(0px, -40px);
        }
    }

    .navigation {
        transition: .25s ease;
    }

    .navigation.sticky {
        height: 50px;
    }

    .navigation.sticky .navigation-item {
        font-size: 1.3rem;
    }

    .navigation-item {
        transition: .25s ease;
    }

    .navigation-item--logo {
        background-color: #6dbbc7;
        position: absolute;
        left: 0;
        transition: .25s ease;
    }

    .navigation-item--logo.active {
        transition: .25s .25s ease;
        transform: translateX(-100%);
    }

    .navigation-item--logo svg {
        height: 40px;
        width: 100%;
        margin-right: 0;
    }

</style>
