<template>

    <ul class="breadcrumb">
        <li class="breadcrumb-item" v-for="(item, index) in items">
            <router-link :to="item.url" class="breadcrumb-link" v-if="items && items.length && index !== items.length - 1">{{ item.text }}</router-link>
            <span v-else class="breadcrumb-text">{{ item.text }}</span>
        </li>
    </ul>

</template>

<script>
    export default {
        name: "Breadcrumb",
        props: ['items'],
    }
</script>
