<template>

    <div class="simulation">

        <link-header style="min-height: 30rem">
            <h1 class="simulation-main-title">
                Simuler votre épargne
            </h1>
        </link-header>

        <div class="container">

            <breadcrumb
                    :items="[{text: 'Accueil', url: '/'}, { text: 'Simulation' } ]"></breadcrumb>

            <div class="simulation-form" v-if="page === 'form'">
                <div class="simulation-popin" v-if="showPopin">
                    <p class="simulation-popin-title">
                        Simuler votre épargne
                    </p>
                    <p class="simulation-description-small">
                        En fonction de votre horizon de placement, du montant investi et de votre profil d’investisseur,
                        nous créons une allocation personnalisée, calculée à partir de vos hypothèses. Elle sera
                        automatiquement adaptée chaque mois, si nécessaire, en fonction de la situation des marchés, de
                        façon à respecter votre profil de risque sur la durée. Les <span @click="showMore = !showMore"
                                                                                         class="help">ETF</span> et les
                        profils de LINK VIE peuvent varier à
                        la
                        hausse comme à la baisse et présentent un risque de perte en capital. Le scénario le plus
                        défavorable ne constitue pas une indication de la perte maximale possible, qui peut atteindre
                        100%
                        du capital investi.
                    </p>

                    <div v-show="showMore" class="accordion-more accordion-content">
                        <div class="accordion-more-close" @click="showMore = false"></div>
                        Un ETF (Exchange Traded Fund) est un organisme de placement collectif ayant pour objectif de
                        répliquer un indice des marchés actions ou de taux. Ainsi, la performance de ce type de support
                        dépend de la variation à la hausse ou à la baisse de l’indice que l’ETF réplique. Les ETF sont
                        également cotés en bourse et peuvent s’échanger sur le marché tout au long de la journée de
                        cotation. Les ETF sont des unités de compte et présentent un risque de perte en capital. Se
                        référer à la Note d’information du contrat d’assurance vie pour tout renseignement
                        complémentaire.
                    </div>

                    <div @click="start" class="button simulation-popin-confirm">Je commence ma simulation
                    </div>
                </div>

                <div class="simulation-step">

                    <div class="simulation-step--number">

                        <span class="circle-line"></span>

                        <svg class="circle-arc" :style="{ strokeDashoffset : circle }"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                            <circle cx="30" cy="30" r="28.5"/>
                        </svg>

                        <svg class="circle-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                            <circle cx="30" cy="30" r="28.5"/>
                        </svg>

                        <span class="circle-number">0<span>{{ step }}</span></span>
                    </div>

                    <transition-group v-bind:css="false" v-on:enter="enter" v-on:leave="leave"
                                      v-on:before-leave="beforeLeave">
                        <div key="s1" class="simulation-content" v-if="step === 1">
                            <p class="simulation-step--q">
                                Quel montant souhaitez-vous investir ?
                            </p>
                            <div class="simulation-step--r">
                                <input autofocus onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                       @keypress.enter="next(step)" v-model.number="data.initial" type="text"
                                       min="1"
                                       maxlength="10" placeholder="1000"
                                       required> €
                            </div>
                            <p class="warning warning--simulation warning--simulation--noAnim">
                                <span></span>
                                <span></span>
                                <span v-if="settings && settings.simulator_info_1" v-html="settings.simulator_info_1">L'épargne Link Vie est accessible dès XX€ d'investissement.</span>
                            </p>
                        </div>
                        <div key="s2" class="simulation-content" v-if="step === 2">
                            <p class="simulation-step--q">
                                Quel versement régulier envisagez-vous ?
                            </p>
                            <div class="simulation-step--r">
                                <input autofocus @keypress.enter="next(step)" v-model.number="data.investment"
                                       type="text" min="1"
                                       onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                       maxlength="10" :placeholder="placeholder"
                                       required> € par
                                <select @keypress.enter="next(step)" name="" id="" v-model="data.time">
                                    <option value="mois">mois</option>
                                    <option value="trimestre">trimestre</option>
                                    <option value="semestre">semestre</option>
                                    <option value="an">an</option>
                                </select>

                                <div class="simulation-step--r-checkbox">
                                    <input type="checkbox" id="regular" v-model="data.regular"> <label for="regular">Je
                                    ne souhaite pas mettre en place de versements réguliers</label>
                                </div>

                            </div>
                            <p class="warning warning--simulation">
                                <span></span>
                                <span></span>
                                <span>
                                    L'investissement minimum est de
                                    <i>
                                        <template v-if="data.time === 'mois'">50€</template>
                                        <template v-if="data.time === 'trimestre'">150€</template>
                                        <template v-if="data.time === 'semestre'">300€</template>
                                        <template v-if="data.time === 'an'">600€</template>
                                    </i>
                                     par <i>
                                    <template v-if="data.time === 'mois'">mois</template>
                                    <template v-if="data.time === 'trimestre'">trimestre</template>
                                    <template v-if="data.time === 'semestre'">semestre</template>
                                    <template v-if="data.time === 'an'">an</template>
                                    </i>.
                                </span>
                            </p>
                        </div>
                        <div key="s3" class="simulation-content" v-if="step === 3">
                            <p class="simulation-step--q">
                                Quelle est la durée que vous envisagez pour votre investissement ?
                            </p>
                            <div class="simulation-step--r">
                                <input autofocus @keypress.enter="next(step)" v-model.number="data.years" type="text"
                                       min="2" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                       maxlength="10" placeholder="2" required> année(s)
                            </div>
                            <p class="warning warning--simulation">
                                <span></span>
                                <span></span>
                                <span v-if="settings && settings.simulator_info_3" v-html="settings.simulator_info_3">Vous pourrez dans tous les cas retirer vorre argent à tout moment.</span>
                            </p>
                        </div>
                        <div key="s4" class="simulation-content" v-if="step === 4">
                            <p class="simulation-step--q">
                                Quel est votre profil de risque ?
                            </p>

                            <div class="simulation-profile">
                                <div class="simulation-profile-item" @click="setProfile($event, profile)"
                                     v-for="profile in profiles" :class="{ '_active' : data.profile === profile.name }">


                                    <div class="simulation-profile-active" v-if="data.profile === profile.name">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.13 33.94">
                                            <path d="M35.06,0,31.53,3.53,14.81,20.25l-4.47-3.94L6.59,13,0,20.56l3.78,3.28,8,7,3.5,3.1,3.31-3.32,20-20,3.53-3.53Z"/>
                                        </svg>
                                    </div>
                                    <!--
                                    <svg class="simulation-profile-border" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 203 203">
                                        <path d="M21,1.5H182c10.75,0,19.46,9.16,19.46,20.45v159.1c0,11.29-8.71,20.45-19.46,20.45H21c-10.75,0-19.46-9.16-19.46-20.45V22C1.5,10.66,10.21,1.5,21,1.5Z"/>
                                    </svg>
                                    -->
                                    <div class="profile-header">
                                        <div class="profile-header-icon"
                                             :style="{ backgroundImage: profile.icon ? `url(/storage/${profile.icon})` : 'none' }"></div>
                                        <p class="profile-header-name">{{ profile.name }}</p>
                                        <p class="profile-header-description" v-if="data.profile === profile.name"
                                           v-html="profile.description"></p>
                                    </div>

                                </div>
                            </div>

                            <p class="warning warning--simulation warning--simulation--noAnim">
                                <span></span>
                                <span></span>
                                <span v-if="settings && settings.simulator_info_4" v-html="settings.simulator_info_4">Votre profil de risque définitif sera déterminé à la souscription grâce à un questionnaire d’évaluation.</span>
                            </p>

                        </div>
                    </transition-group>

                    <div class="simulation-buttons">
                        <div class="button button-simple" @click="prev(step)">Retour</div>
                        <div class="button" @click="next(step)" :class="{ 'button-valid' : !canValid }">Valider
                        </div>
                    </div>


                </div>

            </div>

            <div class="simulation-result" v-show="page === 'result'">

                <h2 class="simulation-title">
                    Rappel de vos hypothèses
                </h2>

                <p class="simulation-description">
                    Vous souhaitez effectuer un versement initial de <span @click="page = 'form'; step = 1">{{ data.initial }}</span>€,<br>
                    puis des versement réguliers de <span @click="page = 'form'; step = 2">{{ data.investment }}</span>€
                    chaque
                    <span @click="page = 'form'; step = 2">{{ data.time }}</span><br>
                    sur <span @click="page = 'form'; step = 3">{{ data.years }}</span> ans avec un profil de risque
                    <span @click="page = 'form'; step = 4">{{ data.profile }}</span>.
                </p>


                <div class="simulation-result-row">

                    <div class="simulation-result-table">
                        <table class="table first">
                            <thead>
                            <tr>
                                <th colspan="3"><span></span> Résultat de ma simulation</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="first">
                                <td colspan="3">
                                    <table class="table">
                                        <tr>
                                            <td>
                                                Cumul de vos versements (initial et réguliers) sur la durée choisie
                                                <span> {{ total().total.toLocaleString() }}€ </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <!--
                            <tr class="second">
                                <td>
                                    <strong>Scénario<br> Défavorable</strong>
                                    <p>Montant de votre<br> épargne à terme</p>
                                    <span class="primary">x1xxx€</span>

                                    <span class="secondary">soit xxx€ d'intérêts</span>
                                </td>
                                <td>
                                    <strong>Scénario<br> Médian</strong>
                                    <p>Montant de votre<br> épargne à terme</p>
                                    <span class="primary">x1xxx€<br>& x3xxx€</span>

                                    <span class="secondary">soit xxx€ d'intérêts</span>
                                </td>
                                <td>
                                    <strong>Scénario<br> Favorable</strong>
                                    <p>Montant de votre<br> épargne à terme</p>
                                    <span class="primary">x4xxx€</span>

                                    <span class="secondary">soit xxx€ d'intérêts</span>
                                </td>
                            </tr>
                            -->
                            </tbody>
                        </table>
                    </div>
                    <div class="simulation-result-aside">
                        <!--
                        <div class="simulation-result-comparaison">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.95 494.96">
                                <path d="M495,227.48H357.09a89.31,89.31,0,0,0-26.25-63.36l97.49-97.49A226.78,226.78,0,0,1,495,227.48Z"
                                      style="fill:#f25314"/>
                                <path d="M317.09,267.48H455C455,393.11,353.11,495,227.48,495A226.77,226.77,0,0,1,66.62,428.33l97.49-97.49a89.61,89.61,0,0,0,153-63.36Z"
                                      style="fill:#ead121"/>
                                <path d="M428.33,66.63l-97.49,97.49a89.31,89.31,0,0,0-63.36-26.25V0A226.75,226.75,0,0,1,428.33,66.63Z"
                                      style="fill:#ff641a"/>
                                <path d="M227.48,40V177.87a89.6,89.6,0,0,0-63.37,153L66.62,428.33A226.78,226.78,0,0,1,0,267.48C0,141.85,101.84,40,227.48,40Z"
                                      style="fill:#ffeb2e"/>
                            </svg>

                            <p class="first">
                                Si vous aviez investi<br>
                                le même montant<br>
                                sur un Livret A<br>
                                <span>(calculé sur un rendement<br>
                                annuel de 0,75 %),</span><br>
                                votre épargne serait de
                            </p>

                            <p class="second">xxx€</p>

                        </div>
                        -->
                        <a href="" target="_blank" class="simulation-result-subscribe" @click.prevent="$root.subscribe">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 507.2 507.2">
                                    <circle class="yellow" cx="253.6" cy="253.6" r="253.6" style="fill:#ffda2d"/>
                                    <path class="yellow-dark"
                                          d="M188.8,368,319.2,498.4c108-28.8,188-127.2,188-244.8v-7.2L404.8,152Z"
                                          style="fill:#e5be1c"/>
                                    <path d="M260,310.4c11.2,11.2,11.2,30.4,0,41.6l-23.2,23.2c-11.2,11.2-30.4,11.2-41.6,0L93.6,272.8c-11.2-11.2-11.2-30.4,0-41.6L116.8,208c11.2-11.2,30.4-11.2,41.6,0Z"
                                          style="fill:#fff"/>
                                    <path d="M348.8,133.6c11.2-11.2,30.4-11.2,41.6,0l23.2,23.2c11.2,11.2,11.2,30.4,0,41.6l-176,175.2c-11.2,11.2-30.4,11.2-41.6,0l-23.2-23.2c-11.2-11.2-11.2-30.4,0-41.6Z"
                                          style="fill:#fff"/>
                                </svg>
                            </div>
                            <div>
                                Je souscris
                            </div>
                        </a>

                    </div>
                </div>
                <!--
                                <div class="simulation-result-graph">
                                    <table class="table first">
                                        <thead>
                                        <tr>
                                            <th colspan="3"><span></span> Représentation graphique de l'évolution de votre épargne</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="first">
                                            <td colspan="3">
                                                <table class="table">
                                                    <tr>
                                                        <td class="semi second" :class="{ 'active' : graph === 0}" @click="graph = 0">
                                                            Sur les 10 dernières années
                                                        </td>
                                                        <td class="semi second" :class="{ 'active' : graph === 1}" @click="graph = 1">
                                                            Sur les 10 prochaines années
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr class="second">
                                            <td colspan="3">
                                                <canvas id="myChart" width="400" height="100"></canvas>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                -->
                <div class="simulation-result-information">
                    <div class="table-header">
                        <span></span> Composition de mon épargne
                    </div>
                    <div class="table-panels">
                        <div class="table-panels-item table-panels-item--50" @click="composition = 0"
                             :class="{ 'active': composition === 0}">
                            Pourcentage
                        </div>
                        <div class="table-panels-item table-panels-item--50" @click="composition = 1"
                             :class="{ 'active': composition === 1}">
                            Euros
                        </div>
                    </div>
                    <div class="table-panels-container dir-column">
                        <div class="fonds">
                            <div class="fond" v-for="fond in repartitions">
                                <div class="fond-percent" :style="{ width: fond.pourcentage * 100 + '%'}"></div>
                                <div class="fond-number" v-if="composition === 0">{{ (fond.pourcentage *
                                    100).toFixed(1) + '%' }}
                                </div>
                                <div class="fond-number fond-number--black" v-if="composition === 1">{{
                                    totalB(fond.pourcentage) }}
                                </div>
                                <div class="fond-name" :class="{ 'fond-name--right' : composition === 1 }">
                                    {{ fond.intituleDuFond }}
                                </div>
                            </div>
                        </div>
                        <p class="simulation-paragraph">Simulation réalisée sur la base de l’allocation d’actifs en
                            cours sur ce profil, peut ne pas correspondre à l’allocation initiale de votre contrat</p>
                        <p class="simulation-disclaimer">Les unités de compte présentent un risque de perte en
                            capital</p>
                    </div>
                </div>


                <div class="simulation-result-information">
                    <div class="table-header">
                        <span></span> Informations / Profil de risque
                    </div>
                    <div class="table-panels">
                        <div class="table-panels-item"
                             v-for="item in profiles" :class="{ 'active' : profiled.name === item.name}"
                             @click="profiled = item">
                            <img :src="item.icon ? `/storage/${item.icon}` : null" alt="">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="table-panels-container">
                        <div>
                            <p class="table-panels-item-name">
                                <img :src="profiled && profiled.icon ? `/storage/${profiled.icon}` : null"
                                     alt="">
                                {{ profiled.name }}
                            </p>

                            <div>
                                <div class="table-panels-item-description">
                                    <p v-html="profiled.long_description"></p>
                                </div>
                                <div>
                                    <template v-if="profiled.volatilite">
                                        <p>Volatilité cible</p>
                                        <p>{{ profiled.volatilite }}% / an</p>
                                    </template>
                                    <template v-if="profiled.part_actions">
                                        <p>Part actions</p>
                                        <p>{{ profiled.part_actions }}%</p>
                                    </template>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="subscribe-tips" v-if="settings && settings.simulator_tips_title">
                        <div class="subscribe-tips-title">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.9 79.9">
                                <circle cx="40" cy="40.3" r="21.5"/>
                                <path d="M31.9,71.5a5.78,5.78,0,0,0,4.3,3.7v1a3.7,3.7,0,0,0,7.4,0v-1a5.44,5.44,0,0,0,4.3-3.7Z"/>
                                <rect x="31.6" y="66" width="16.7" height="3.7"/>
                                <rect x="31.6" y="60" width="16.7" height="3.7"/>
                                <path d="M1.9,38.1A1.9,1.9,0,0,0,0,40a1.84,1.84,0,0,0,1.9,1.9h9.3a1.9,1.9,0,1,0,0-3.8Z"/>
                                <path d="M13,11.2a1.76,1.76,0,0,0-1.3.6,1.87,1.87,0,0,0,0,2.6L18.3,21a1.84,1.84,0,1,0,2.6-2.6l-6.6-6.6A1.61,1.61,0,0,0,13,11.2Z"/>
                                <path d="M40,0a1.9,1.9,0,0,0-1.9,1.9v9.3a1.9,1.9,0,0,0,3.8,0V1.9A2,2,0,0,0,40,0Z"/>
                                <path d="M66.9,11.2a1.76,1.76,0,0,0-1.3.6L59,18.4A1.84,1.84,0,1,0,61.6,21l6.6-6.6a1.87,1.87,0,0,0,0-2.6A1.76,1.76,0,0,0,66.9,11.2Z"/>
                                <path d="M68.8,38.1a1.9,1.9,0,1,0,0,3.8H78A1.9,1.9,0,0,0,79.9,40,1.84,1.84,0,0,0,78,38.1Z"/>
                            </svg>
                            <span v-html="settings.simulator_tips_title"></span>
                        </div>
                        <div class="subscribe-tips-content" v-html="settings.simulator_tips_content"></div>
                    </div>

                    <a target="_blank" @click.prevent="$root.subscribe" href=""
                       class="simulation-result-subscribe simulation-result-subscribe--center">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 507.2 507.2">
                                <circle class="yellow" cx="253.6" cy="253.6" r="253.6" style="fill:#ffda2d"/>
                                <path class="yellow-dark"
                                      d="M188.8,368,319.2,498.4c108-28.8,188-127.2,188-244.8v-7.2L404.8,152Z"
                                      style="fill:#e5be1c"/>
                                <path d="M260,310.4c11.2,11.2,11.2,30.4,0,41.6l-23.2,23.2c-11.2,11.2-30.4,11.2-41.6,0L93.6,272.8c-11.2-11.2-11.2-30.4,0-41.6L116.8,208c11.2-11.2,30.4-11.2,41.6,0Z"
                                      style="fill:#fff"/>
                                <path d="M348.8,133.6c11.2-11.2,30.4-11.2,41.6,0l23.2,23.2c11.2,11.2,11.2,30.4,0,41.6l-176,175.2c-11.2,11.2-30.4,11.2-41.6,0l-23.2-23.2c-11.2-11.2-11.2-30.4,0-41.6Z"
                                      style="fill:#fff"/>
                            </svg>
                        </div>
                        <div>
                            Je souscris
                        </div>
                    </a>

                </div>

                <h3 class="simulation-testimonies-title">Ils ont choisi l'épargne LINK</h3>

                <testimonies :limit="3"></testimonies>

            </div>
        </div>

    </div>

</template>

<script>

    //import Chart from 'chart.js';

    export default {
        name: "Simulation",
        data() {
            return {
                showMore: false,
                showPopin: true,
                loading: false,
                page: 'form',
                //page: 'result',
                step: 1,
                base: 178.7815399169922,
                circle: 178.7815399169922 / 4 * 3,
                data: {
                    initial: null,
                    regular: false,
                    investment: null,
                    time: 'mois',
                    years: null,
                    profile: null,
                },
                sens: 'toLeft',
                chart: null,
                graph: 0,
                profiled: 0,
                composition: 0,
                repartitions: [
                    {
                        "intituleDuFond": "Actions US",
                        "intituleCourtDuFond": "Actions US",
                        "type": "AssetType\tSubAssetType\r\nAction",
                        "codeISIN": "",
                        "pourcentage": 0.2774
                    },
                    {
                        "intituleDuFond": "Actions Europe",
                        "intituleCourtDuFond": "Actions Europe",
                        "type": "Action",
                        "codeISIN": "",
                        "pourcentage": 0.41150000000000003
                    },
                    {
                        "intituleDuFond": "Actions Pays Emergents",
                        "intituleCourtDuFond": "Actions Pays Emergents",
                        "type": "Action",
                        "codeISIN": "",
                        "pourcentage": 0.048600000000000004
                    },
                    {
                        "intituleDuFond": "Actions Japon",
                        "intituleCourtDuFond": "Actions Japon",
                        "type": "Action",
                        "codeISIN": "",
                        "pourcentage": 0.1077
                    },
                    {
                        "intituleDuFond": "Actions Thématiques",
                        "intituleCourtDuFond": "Actions Thématiques",
                        "type": "Action",
                        "codeISIN": "",
                        "pourcentage": 0.066099999999999992
                    },
                    {
                        "intituleDuFond": "Actions Sectorielles",
                        "intituleCourtDuFond": "Actions Sectorielles",
                        "type": "Action",
                        "codeISIN": "",
                        "pourcentage": 0.0887
                    }
                ],
            }
        },
        computed: {
            placeholder() {
                if (this.data.time === 'mois')
                    return '50'
                else if (this.data.time === 'trimestre')
                    return '150'
                else if (this.data.time === 'semestre')
                    return '300'
                else if (this.data.time === 'an')
                    return '600'
            },
            canValid() {
                if (this.step === 1 && this.data.initial !== null && Number.isInteger(this.data.initial) && this.data.initial >= 1000)
                    return true

                else if (this.step === 2 && this.data.regular)
                    return true;

                else if (this.step === 2 && this.data.investment !== null && Number.isInteger(this.data.investment)) {
                    if (this.data.investment >= 50 && this.data.time === 'mois')
                        return true
                    if (this.data.investment >= 150 && this.data.time === 'trimestre')
                        return true
                    if (this.data.investment >= 300 && this.data.time === 'semestre')
                        return true
                    if (this.data.investment >= 600 && this.data.time === 'an')
                        return true
                } else if (this.step === 3 && this.data.years !== null && Number.isInteger(this.data.years))
                    return true

                else if (this.step === 4 && this.data.profile !== null)
                    return true

                return false;
            },
            settings() {
                return this.$root.settings;
            },
            profiles() {

                let product = this.$root.products.find(el => {
                    return el.slug === 'link-vie'
                });

                if (product) {
                    return JSON.parse(product.profiles).map(el => {
                        let item = this.$root.profiles.find(e => el.profil_id === e.id);

                        item.volatilite = el.volatilite;
                        item.part_actions = el.part_actions;

                        return item;

                    })
                }
            },
        },
        watch: {
            'data.regular'() {
                if (this.data.regular)
                    this.data.investment = 0
                /*
            else
                this.getMinimumInvestement()
                */
            },
            'data.time'() {

                if (this.data.regular) {
                    this.data.investment = 0;
                    return;
                }

                //this.getMinimumInvestement()
            },
            step() {
                this.$anime({
                    targets: document.querySelector('.circle-arc'),
                    strokeDashoffset: this.base / 4 * (4 - this.step),
                    easing: 'easeInOutQuad',
                })
            },
            graph() {

                this.chart.data.datasets[0].data = this.random()
                this.chart.data.datasets[1].data = this.random()

                this.chart.update();

            },
            page() {
                if (this.page === 'result') {

                    let profile = this.data.profile.replace(/é/ig, 'e').toUpperCase();

                    axios.get(`/api/simulation/${profile}`, {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    })
                        .then(res => {
                            this.repartitions = JSON.parse(res.data).repartition;
                        })
                        .catch(err => {
                            console.log(err.response)
                        })
                }
            }
        },
        methods: {
            start() {
                this.showPopin = false;
                document.querySelector('input[autofocus=autofocus]').focus()
            },
            getMinimumInvestement() {
                if (this.data.time === 'mois' && this.data.investment < 50)
                    this.data.investment = 50
                else if (this.data.time === 'trimestre' && this.data.investment < 150)
                    this.data.investment = 150
                else if (this.data.time === 'semestre' && this.data.investment < 300)
                    this.data.investment = 300
                else if (this.data.time === 'an' && this.data.investment < 600)
                    this.data.investment = 600
            },
            total() {
                let investement = 0;

                switch (this.data.time) {
                    case 'mois':
                        investement = this.data.investment * 12 * this.data.years;
                        break;
                    case 'trimestre':
                        investement = this.data.investment * 4 * this.data.years;
                        break;
                    case 'semestre':
                        investement = this.data.investment * 2 * this.data.years;
                        break;
                    case 'an':
                        investement = this.data.investment * this.data.years;
                        break;
                }

                return {investement: investement, total: this.data.initial + investement}
            },
            totalB(percent) {
                let n = this.total().total * percent;

                return n.toLocaleString('fr-FR', {style: "currency", currency: "EUR"})
            },
            setProfile(event, profil) {
                this.data.profile = profil.name;
                this.profiled = profil;
            },
            prev(step) {

                if (this.loading)
                    return;

                if (step === 1) {

                    if (window.history && window.history.length > 1)
                        this.$router.go(-1);

                    this.$router.push('/')
                }

                if (step > 1) {

                    this.loading = true;
                    this.sens = 'toRight';

                    this.$anime({
                        targets: document.querySelector('.circle-number span'),
                        translateY: 5,
                        opacity: 0,
                        easing: 'easeInOutQuad',
                        duration: 500,
                        complete: () => {
                            this.step--;
                            this.$anime({
                                targets: document.querySelector('.circle-number span'),
                                translateY: [-5, 0],
                                opacity: [0, 1],
                                easing: 'easeInOutQuad',
                                duration: 250,
                                complete: () => {
                                    this.loading = false
                                }
                            });
                        }
                    })
                }

            },
            next(step) {

                window.scroll({
                    top: 100,
                    behavior: 'smooth'
                })

                if (this.loading || !this.canValid)
                    return;
                /*
                                if(step + 1 === 4) {
                                    setTimeout(() => {
                                        var path = document.querySelector('.simulation-profile-item path');
                                        var length = path.getTotalLength();

                                        alert(length)
                                    }, 2000)

                                }
                */
                if (step <= 3) {

                    if (step <= 2) {
                        setTimeout(() => {
                            document.querySelector('input[autofocus=autofocus]').focus();
                        }, 2000)

                    }

                    this.sens = 'toLeft';
                    this.loading = true;

                    this.$anime({
                        targets: document.querySelector('.circle-number span'),
                        translateY: -5,
                        opacity: 0,
                        easing: 'easeInOutQuad',
                        duration: 500,
                        complete: () => {
                            this.step++;
                            this.$anime({
                                targets: document.querySelector('.circle-number span'),
                                translateY: [5, 0],
                                opacity: [0, 1],
                                easing: 'easeInOutQuad',
                                duration: 250,
                                complete: () => {
                                    this.loading = false
                                }
                            });
                        }
                    })
                }

                if (step === 4) {
                    this.page = 'result';
                }

            },

            beforeLeave(el) {

                el.style.position = 'absolute';

            },
            enter(el, done) {

                let x = this.sens === 'toLeft' ? ['400px', 0] : ['-400px', 0]


                this.$anime({
                    delay: 500,
                    targets: document.querySelector(`.simulation-content:nth-child(2)`),
                    translateX: x,
                    opacity: [0, 1],
                    duration: 1000,
                    easing: 'spring(1, 100, 100, 0)',
                    complete: () => {
                        done();
                    }
                })
            },
            leave(el, done) {

                let x = this.sens === 'toLeft' ? [0, '-400px'] : [0, '400px']

                this.$anime({
                    targets: document.querySelector(`.simulation-content:nth-child(1)`),
                    translateX: x,
                    opacity: [1, 0],
                    duration: 1000,
                    easing: 'spring(1, 100, 100, 0)',
                    complete: () => {
                        done();
                    }
                })
            },
            random() {

                let array = []

                for (let i = 0; i < 12; i++)
                    array.push(Math.random() * 20)

                return array;
            },
        },
        mounted() {
            this.data.initial = this.$root.euros;
            document.title = this.settings && this.settings.site_title ? `Simulation | ${this.settings.site_title}` : 'Simulation';

            /*
                    let ctx = document.getElementById('myChart');
                    this.chart = new Chart(ctx, {
                        type: 'line',
                        data: {
                            labels: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'],
                            datasets: [
                                {
                                    label: '',
                                    data: this.random(),
                                    borderColor: '#354e7c',
                                    fill: false,
                                    pointBackgroundColor: '#ffffff',
                                    pointBorderColor: '#354e7c',
                                    pointRadius: 8,
                                    pointBorderWidth: 4,
                                    lineTension: 0,
                                },
                                {
                                    label: '',
                                    data: this.random(),
                                    borderColor: '#6dbbc7',
                                    fill: false,
                                    pointBackgroundColor: '#ffffff',
                                    pointBorderColor: '#6dbbc7',
                                    pointRadius: 8,
                                    pointBorderWidth: 4,
                                    lineTension: 0,

                                }
                            ],
                        },
                        options: {}
                    })

 */

        }
    }
</script>
