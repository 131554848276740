<template>

    <div class="backToTop" title="Retourner en haut de la page" :class="{ 'active': active }" @click="top">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 777 471">
            <polygon points="389 189 682 471 777 372 389 0 0 372 95 471 389 189" style="fill:white;fill-rule:evenodd"/>
        </svg>
    </div>

</template>

<script>
    export default {
        name: "BackToTop",
        data() {
            return {
                active: false,
            }
        },
        mounted() {

            window.addEventListener('scroll', () => {

                if(window.pageYOffset > 100)
                    this.active = true
                else
                    this.active = false

            })

        },
        methods: {
            top() {

                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                });

            }
        }
    }
</script>

<style scoped lang="scss">

    .backToTop {
        position: fixed;
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        background: black;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        display: flex;
        transform: translateY(80px);
        transition: .25s ease;
        cursor: pointer;
        border-radius: 10px;

        &.active {
            transform: translateY(0px);
        }

        svg {
            width: 60%;
            height: 60%;
        }

    }

</style>
