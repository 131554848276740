<template>

    <div class="profil">

        <div class="profil-choice">
            <div class="profil-button profil-button-previous" @click="previous">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                    <polygon points="189 388 471 95 372 0 0 388 372 777 471 682 189 388"
                             style="fill:#2c2c2c;fill-rule:evenodd"/>
                </svg>
            </div>
            <div class="profil-title">
                <div class="profil-icon"
                     :style="{ backgroundImage: getProfile(profiles[index].profil_id).icon ? `url(/storage/${getProfile(profiles[index].profil_id).icon})` : 'none' }"></div>
                <p class="profil-text">
                    {{ getProfile(profiles[index].profil_id).name }}
                </p>
            </div>
            <div class="profil-button profil-button-next" @click="next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                    <polygon points="282 389 0 682 99 777 471 389 99 0 0 95 282 389"
                             style="fill:#2c2c2c;fill-rule:evenodd"/>
                </svg>
            </div>
        </div>


        <div class="profil-info" v-if="profiles[index].volatilite">

            <div class="profil-info-title">
                Volatilité<br> Cible
            </div>

            <div class="profil-info-content">
                {{ profiles[index].volatilite }}<sup>%</sup>/an
            </div>

        </div>

        <div class="profil-info" v-if="profiles[index].part_actions">
            <div class="profil-info-title">
                Part Actions
            </div>
            <div class="profil-info-content">
                {{ profiles[index].part_actions }}<sup>%</sup>
            </div>
        </div>

        <div class="profil-document" v-if="profiles[index].file">
            <a :href="`/storage/products/contrats/${profiles[index].fileurl}`" target="_blank" class="profil-document-content">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.77 169.19">
                    <path d="M3.6,0A3.91,3.91,0,0,0,0,3.6V151.4A3.75,3.75,0,0,0,3.6,155H108.14a3.74,3.74,0,0,0,3.6-3.6V36.05a3.64,3.64,0,0,0-1.07-2.59L78.23,1A3.66,3.66,0,0,0,75.7,0ZM7.21,7.21H72.09V36.05a3.75,3.75,0,0,0,3.61,3.6h28.83V147.79H7.21ZM79.3,12.28,99.47,32.44H79.3Zm-55.87,49a3.61,3.61,0,0,0,0,7.21H88.31a3.61,3.61,0,1,0,0-7.21Zm0,28.84a3.61,3.61,0,0,0,0,7.21H88.31a3.61,3.61,0,1,0,0-7.21Zm0,28.83a3.61,3.61,0,0,0,0,7.21H88.31a3.61,3.61,0,1,0,0-7.21Z"
                          transform="translate(0)" style="fill:#fff"/>
                    <circle cx="99.27" cy="126.69" r="42.5" style="fill:#fdb62f"/>
                    <path d="M112.38,137.92a1.88,1.88,0,0,1-1.87,1.88H88a1.88,1.88,0,0,1,0-3.75h22.47A1.87,1.87,0,0,1,112.38,137.92ZM98,131.77a2,2,0,0,0,.59.39,1.85,1.85,0,0,0,1.46,0,1.88,1.88,0,0,0,.6-.39l6.61-6.63a1.87,1.87,0,1,0-2.43-2.85c-.08.07-.14.14-.21.21l-3.42,3.42V115.46a1.88,1.88,0,0,0-3.75,0v10.46L94,122.5a1.87,1.87,0,0,0-2.64,2.64Z"
                          style="fill:#fff"/>
                </svg>
                {{ profiles[index].file }}
            </a>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Profils",
        props: ['item'],
        data() {
            return {
                index: 0,
            }
        },
        computed: {
            profiles() {
                return JSON.parse(this.item.profiles).filter(el => {
                    return el.volatilite || el.part_actions
                })
            }
        },
        methods: {
            getProfile(id) {
                return this.$root.profiles.find(el => {
                    return el.id === id;
                })
            },
            next() {
                this.index = this.index < this.profiles.length - 1 ? this.index + 1 : 0
            },
            previous() {
                this.index = this.index > 0 ? this.index - 1 : this.profiles.length - 1
            }
        }

    }
</script>

<style scoped>

</style>
