<template>

    <div class="promo">
        <svg class="promo-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
            <circle cx="200.5" cy="227.5" r="144.5" style="fill:#fff"/>
            <path d="M213.34,0C95.51,0,0,95.5,0,213.32S95.51,426.67,213.34,426.67s213.33-95.49,213.33-213.35S331.16,0,213.34,0Zm0,99.49a26.79,26.79,0,1,1-26.78,26.79A26.78,26.78,0,0,1,213.33,99.49Zm46.88,227.69H166.46V287h20.09V206.64H166.46V166.46h73.66V287h20.09v40.18Z"
                  style="fill:#354e7c"/>
        </svg>
        <div class="promo-content" v-html="parsePromotion"></div>
    </div>

</template>

<script>
    export default {
        name: "Promo",
        props: ['promo'],
        computed: {
            parsePromotion() {

                let array = this.promo.split(';');

                if (array.length > 1)
                    return `<strong>${array[0]}</strong>${array[1]}`
                else
                    return this.promo;

            }
        }
    }
</script>

<style lang="scss">

    .promo {
        background: #6dbbc7;
        height: 12rem;
        padding: 3rem;
        color: white;
        position: absolute;
        left: 9rem;
        right: 9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.6rem;
        font-weight: bold;

        &-icon {
            width: 23px;
            height: 23px;
            margin-right: 24px;
        }

        strong {
            font-size: 4rem;
            margin-right: 4rem;
        }

        &-content {
            display: flex;
            align-items: center;
        }
    }

</style>
