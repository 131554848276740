<template>

    <div class="last-news" :class="{ 'last-news--center' : center !== undefined}">

        <h3 class="homepage-subtitle">{{ title }}</h3>


        <div class="last-news-controls" v-if="$root.posts.slice(3, 8).length">
            <div class="last-news-control last-news-control--left" @click="previous">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                    <polygon points="189 389 471 682 372 777 0 389 372 0 471 95 189 389"/>
                </svg>
            </div>
            <div class="last-news-control last-news-control--right" @click="next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                    <polygon points="282 389 0 682 99 777 471 389 99 0 0 95 282 389"/>
                </svg>
            </div>
        </div>


        <div class="last-news-slider">

            <div class="last-news-slide">
                <div class="last-news-column-full">
                    <div class="last-news-item" v-for="(post, index) in $root.posts.slice(0, 1)">
                        <div class="posts-item--image">

                            <div class="posts-item--image-src"
                                 :style="{ 'backgroundImage' : `url(/storage/${post.image})`}">
                            </div>
                            <div v-if="post.category" class="posts-item--tag"
                                 :class="{ 'posts-item--tag--secondary' : post.category.slug === 'epargne'}"><span
                                    class="icon"
                                    :class="`icon--${post.category.slug}`"></span>
                                {{ post.category.name }}
                            </div>

                        </div>

                        <p class="posts-item--date">{{ post.created_at }}</p>
                        <router-link :to="`/actualites/${post.slug}`" class="posts-item--title">{{ post.title }}
                        </router-link>
                        <p class="posts-item--excerpt" v-html="post.excerpt"></p>

                    </div>
                </div>
                <div class="last-news-column">
                    <div class="last-news-item" v-for="post in $root.posts.slice(1, 3)">

                        <div v-if="post.category" class="posts-item--tag"
                             :class="{ 'posts-item--tag--secondary' : post.category.slug === 'epargne'}">
                            <span class="icon" :class="`icon--${post.category.slug}`"></span>
                            {{ post.category.name }}
                        </div>
                        <p class="posts-item--date">{{ post.created_at }}</p>
                        <router-link :to="`/actualites/${post.slug}`" class="posts-item--title">{{ post.title }}
                        </router-link>
                        <p class="posts-item--excerpt" v-html="post.excerpt"></p>
                    </div>
                </div>

            </div>

            <div class="last-news-slide last-news-slide--column" v-if="$root.posts.slice(3, 8).length">
                <div class="last-news-item" v-for="post in $root.posts.slice(3, 8)">
                    <div v-if="post.category" class="posts-item--tag"
                         :class="{ 'posts-item--tag--secondary' : post.category.slug === 'epargne'}"><span class="icon"
                                                                                                           :class="`icon--${post.category.slug}`"></span>
                        {{ post.category.name }}
                    </div>
                    <p class="posts-item--date">{{ post.created_at }}</p>
                    <router-link :to="`/actualites/${post.slug}`" class="posts-item--title">{{ post.title }}
                    </router-link>
                    <p class="posts-item--excerpt" v-html="post.excerpt"></p>
                </div>
            </div>

        </div>


        <router-link to="/actualites" class="button">Voir toutes nos publications</router-link>

    </div>

</template>

<script>
    export default {
        name: "LastNews",
        props: ['center', 'title'],
        data() {
            return {
                flkty: null,
            }
        },
        mounted() {

            setTimeout(() => {
                axios.post('/api/posts', {items: this.$root.posts})
                    .then(res => {

                        res.data.forEach(el => {
                            this.$root.posts.push(el)
                        })

                        setTimeout(() => {
                            let elem = document.querySelector('.last-news-slider');

                            this.flkty = new this.$flickity(elem, {
                                cellAlign: 'left',
                                contain: true,
                                prevNextButtons: false,
                                pageDots: false,
                                wrapAround: true,
                                bgLazyLoad: true,
                                adaptiveHeight: true
                            })
                        }, 1000)


                    })
                    .catch(err => {
                        console.log(err.response)
                    })
            }, 1000)


        },
        methods: {
            previous() {
                this.flkty.previous()
            },
            next() {
                this.flkty.next()
            }
        }
    }
</script>
