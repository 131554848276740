<template>
  <div class="contact">
    <link-header>
      <h1 class="header-text">Nous contacter</h1>
    </link-header>
    <div class="container">

      <breadcrumb
          :items="[{text: 'Accueil', url: '/'}, {text: 'Contact'} ]"></breadcrumb>
    </div>
    <div class="contact-background">
      <div class="contact-container container">

        <div class="contact-title">
          Vous avez une question ?
        </div>

        <p class="contact-paragraph">
          Pour toute question technique relative à votre navigation sur le site, à votre connexion aux espaces
          sécurisés ou au remplissage du questionnaire d’établissement de votre profil d’investisseur ou au
          process de souscription, vous pouvez contacter notre hotline :<br>
          <span>0806 800 020</span><br>
          ou envoyer un mail à l’adresse suivante :<br>
          <a href="mailto:hotline@linkbyprimonial.fr">hotline@linkbyprimonial.fr</a>.

        </p>

        <p class="contact-paragraph">
          Pour toute question concernant votre profil, votre souscription,<br>
          la gestion de votre patrimoine, vous pouvez vous adresser directement à votre parrain financier.
        </p>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contact",
}
</script>

<style lang="scss">

</style>
