<template>

    <a :href="twitter" target="_blank" class="twblock">
        <div class="twblock--text">
            <span>Suivez</span>
            <span>Nous</span>
            <span>Sur</span>
            <span>Twitter</span>
        </div>
        <div class="twblock--logo"></div>
    </a>

</template>

<script>
    export default {
        name: "TwitterBlock",
        data() {
            return {
                observer: null,
            }
        },
        computed: {
          twitter() {
              return this.$root.settings && this.$root.settings.twitter_url ? this.$root.settings.twitter_url : 'https://twitter.com/linkbyprimonial';
          }
        },
        mounted() {
            let config = {
                rootMargin: '-200px 100px',
                threshold: 0.01
            };

            this.observer = new IntersectionObserver(this.onIntersection, config);
            let elem = document.querySelector('.twblock');
            this.observer.observe(elem);

        },
        methods: {
            onIntersection(entries) {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {

                        this.$anime({
                            targets: document.querySelectorAll('.twblock--text span'),
                            translateY: [20, 0],
                            delay: (el, i) => {
                                return i * 150
                            },
                            opacity: [0, 1],
                        })

                        this.observer.unobserve(entry.target);
                    }
                });
            }
        }
    }
</script>
