/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('intersection-observer');
require('./bootstrap');
require('./polyfill');

import 'promise-polyfill/src/polyfill';

import Vue from 'vue'
import router from './routes.js'
import App from './App.vue'
//import moment from 'moment'
import anime from 'animejs';
import Flickity from 'flickity';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//Vue.prototype.moment = moment;
Object.defineProperty(Vue.prototype, '$anime', { value: anime });
Object.defineProperty(Vue.prototype, '$flickity', { value: Flickity });


Vue.component('main-menu', require('./components/MainMenu.vue').default);
Vue.component('link-header', require('./components/Header.vue').default);
Vue.component('navigation', require('./components/Navigation.vue').default);
Vue.component('rating', require('./components/Rating.vue').default);
Vue.component('breadcrumb', require('./components/Breadcrumb.vue').default);
Vue.component('link-footer', require('./components/Footer.vue').default);
Vue.component('points', require('./components/Points.vue').default);
Vue.component('offers-slider', require('./components/OffersSlider.vue').default);
Vue.component('testimonies', require('./components/Testimonies.vue').default);
Vue.component('last-news', require('./components/LastNews.vue').default);
Vue.component('loading', require('./components/Loading.vue').default);
Vue.component('accordion', require('./components/Accordion.vue').default);
Vue.component('panels', require('./components/Panels.vue').default);
Vue.component('testimonies-slider', require('./components/TestimoniesSlider.vue').default);
Vue.component('reference-item', require('./components/ReferenceItem.vue').default);
Vue.component('quotes', require('./components/Quotes.vue').default);
Vue.component('profils', require('./components/Profils.vue').default);
Vue.component('back-to-top', require('./components/BackToTop.vue').default);
Vue.component('promo', require('./components/Promo.vue').default);
Vue.component('not-found', require('./components/NotFound.vue').default);

new Vue({
    router,
    ...App
}).$mount('#app');
