<template>

    <blockquote class="quote-homepage">
        <p v-show="activeQuote === index" v-for="(quote, index) in items"
           v-html="parse(quote)"></p>
        <div v-show="showMore" class="accordion-more accordion-content">
            <div class="accordion-more-close" @click="showMore = false"></div>
                Un ETF (Exchange Traded Fund) est un organisme de placement collectif ayant pour objectif de répliquer
                un indice des marchés actions ou de taux. Ainsi, la performance de ce type de support dépend de la
                variation à la hausse ou à la baisse de l’indice que l’ETF réplique. Les ETF sont également cotés en
                bourse et peuvent s’échanger sur le marché tout au long de la journée de cotation. Les ETF sont des
                unités de compte et présentent un risque de perte en capital. Se référer à la Note d’information du
                contrat d’assurance vie pour tout renseignement complémentaire.
        </div>
    </blockquote>

</template>

<script>


    export default {
        name: "Quotes",
        data() {
            return {
                activeQuote: 0,
                showMore: false,
            }
        },
        props: ['items'],
        created() {
            document.addEventListener('click', this.toggleShowMore)
        },
        destroyed() {
            document.removeEventListener('click', this.toggleShowMore)
        },
        mounted() {
            this.quoteAnimation();
        },
        beforeDestroy() {
            this.quoteAnimation = () => {
            }
        },
        methods: {
            parse(quote) {
                return quote.replace('ETF', `<span class="showMore">ETF</span>`);
            },
            toggleShowMore(event) {
                if (event.target === document.querySelector('.showMore'))
                    this.showMore = !this.showMore;

                if (this.showMore)
                    this.index = 10
                else
                    this.index = 1

            },
            quoteAnimation() {
                this.quoteAnime = this.$anime({
                    targets: document.querySelector(`.quote-homepage p:nth-child(${this.activeQuote + 1})`),
                    translateX: [0, -document.querySelector(`.quote-homepage p:nth-child(${this.activeQuote + 1})`).clientWidth + 'px'],
                    easing: 'spring(1, 100, 80, 0)',
                    duration: 1000,
                    endDelay: -900,

                    complete: () => {
                        this.activeQuote = this.activeQuote < this.items.length - 1 ? this.activeQuote + 1 : 0;
                        this.$anime({
                            targets: document.querySelector(`.quote-homepage p:nth-child(${this.activeQuote + 1})`),
                            opacity: [0, 1],
                            translateX: ['100%', 0],
                            endDelay: 3000,
                            duration: 1000,
                            easing: 'spring(1, 100, 80, 0)',
                            complete: () => {
                                this.quoteAnimation()
                            }
                        })

                    }
                })

            }
        }
    }
</script>
