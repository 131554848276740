<template>

    <div class="main-loading">
        <div></div>
    </div>


</template>

<script>
    export default {
        name: "Loading",
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            load() {
                if (this.loading)
                    return;

                this.loading = true;

                let timeline = this.$anime.timeline({
                    easing: 'easeInOutQuad',
                    duration: 250,
                });

                timeline
                    .add({
                        targets: '.main-loading',
                        opacity: 1,
                        translateY: '0',
                        duration: 1,
                    })
                    .add({
                        targets: '.main-loading',
                        height: [0, window.innerHeight],
                        duration: 350,
                    })
                    .add({
                        targets: '.main-loading',
                        opacity: 0,
                        duration: 350,
                        complete: () => {
                            this.loading = false;
                        }
                    })
                /*
                .add({
                    targets: '.main-loading',
                    height: 0,

                })

                 */
            }
        }
    }
</script>
