<template>
    <div class="lexique">



        <link-header>
            <h1 class="header-text">Lexique</h1>
        </link-header>
        <div class="container">

            <breadcrumb
                    :items="[{text: 'Accueil', url: '/'}, {text: 'Lexique'} ]"></breadcrumb>
        </div>

        <div class="container">
            <div class="autocomplete-container">
                <div class="autocomplete">

                    <label for="autocomplete-search" class="autocomplete-render">
                        <svg class="autocomplete-icon" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 450.9 451">
                            <path
                                    d="M447,428,337.4,318.4a191.52,191.52,0,0,0,47.2-126.1C384.6,86.2,298.3,0,192.3,0S0,86.3,0,192.3,86.3,384.6,192.3,384.6a191.52,191.52,0,0,0,126.1-47.2L428,447a13.59,13.59,0,0,0,9.5,4,13.17,13.17,0,0,0,9.5-4,13.52,13.52,0,0,0,0-19M192.2,357.7c-91.1,0-165.3-74.2-165.3-165.4S101.1,27,192.2,27s165.3,74.2,165.3,165.3S283.4,357.7,192.2,357.7"/>
                        </svg>
                        <span class="autocomplete-string" v-html="search ? search.replace(' ', '&nbsp;') : null"></span>
                        <span class="autocomplete-tips" v-html="endWord ? endWord.replace(' ', '&nbsp;') : null"></span>
                    </label>

                    <input id="autocomplete-search" type="text" class="input-autocomplete"
                           @input="evt => search = evt.target.value"
                           v-on:keypress.enter="valid">
                </div>
            </div>

            <div class="lexique-list">
                <div class="lexique-list-wrap" v-for="(item, letter) in listing">
                    <div class="lexique-list-letter">{{ letter }}</div>
                    <div class="lexique-list-item" v-for="word in item">
                        <p>{{ word.word }}</p>
                        <p v-html="word.description ? word.description.replace('\n', '<br>') : null"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Lexique",
        watch: {
            search() {
                if (this.search)
                    this.searchWord()
                else
                    this.endWord = null;
            }
        },
        data() {
            return {
                search: null,
                endWord: null,
                src: null,
            }
        },
        computed: {
            listing() {

                if (!this.src)
                    return;

                let list = this.src;
                list = list.sort((a, b) => {
                    if (a.word.toLowerCase() > b.word.toLowerCase())
                        return 1
                    else if (a.word.toLowerCase() < b.word.toLowerCase())
                        return -1
                    else
                        return 0
                })


                if (this.search)
                    list = list.filter(el => {
                        return el.word.toLowerCase().startsWith(this.search.toLowerCase())
                    })

                let letters = {};

                for (let i = 0; i < list.length; i++) {
                    let letter = list[i].word.substr(0, 1).toLowerCase();

                    if (!letters[letter]) {
                        letters[letter] = [];
                        letters[letter].push({word: list[i].word, description: list[i].description})

                    } else {
                        let exist = letters[letter].find(el => {
                            return el.word.toLowerCase() === list[i].word.toLowerCase();
                        })

                        if (!exist)
                            letters[letter].push({word: list[i].word, description: list[i].description});
                    }

                }

                return letters;

            }
        },
        mounted() {
            axios.get('/api/lexiques')
                .then(res => {
                    this.src = res.data;
                })

        },
        methods: {
            searchWord() {

                const regex = this.search.toLowerCase()

                let src = this.src

                src = src.sort((a, b) => {
                    if (a.word.toLowerCase() > b.word.toLowerCase())
                        return 1
                    else if (a.word.toLowerCase() < b.word.toLowerCase())
                        return -1
                    else
                        return 0
                })

                let w = src.find(el => {
                    return el.word.toLowerCase().startsWith(regex)
                })

                if (w) {
                    this.endWord = w.word.substr(this.search.length);
                } else {
                    this.endWord = null;
                }


            },
            valid() {
                if (this.search && this.endWord !== null)
                    this.search = this.search + this.endWord;
            },
        }
    }
</script>

<style lang="scss">


    #app {
        overflow: initial;
    }

    .lexique {
        .container {
            position: relative;
            top: 0;
        }
    }

    .lexique-list {

        &-letter {
            color: #6dbbc7;
            font-weight: bold;
            margin-bottom: 30px;
            font-size: 25px;
            text-transform: uppercase;
        }

        &-item {

            color: #505050;
            font-size: 1.8rem;

            &:last-child {
                margin-bottom: 60px;
            }

            > p:first-child {
                font-weight: bold;
                margin-bottom: 0;
                margin-top: 0;
            }

            > p:last-child {
                margin-top: 20px;
                margin-bottom: 50px;
            }

        }
    }

    .autocomplete {
        position: relative;
        font-family: Raleway, sans-serif !important;

        &-container {
            background: white;
            padding-top: 60px;
            position: sticky;
            top: 0;
            z-index: 9;
            margin: auto;
            margin-top: 0;
            margin-bottom: 65px;
            max-width: 1280px;
            width: 100%;

            @media screen and (max-width: 600px) {
                top: 0;
                padding-top: 70px;
            }
        }

        &-icon {
            position: absolute;
            left: 20px;
            top: 20px;
            width: 21px;
            height: 21px;
        }

        &-render {
            position: absolute;
            display: flex;
            align-items: center;
            padding: 20px 20px 20px 50px;
            font-size: 1.8rem;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            border: 1px solid #dbe6ed;
        }

        &-tips {
            opacity: .5;
        }

        input {
            z-index: 9999;
            width: 100%;
            padding: 20px 20px 20px 50px;
            font-size: 1.8rem;
            font-family: Raleway, sans-serif !important;
            border: 1px solid #dbe6ed;
        }
    }

</style>
