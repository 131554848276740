<template>

    <header class="header">
        <div class="container">
            <router-link class="header-logo" to="/">Link by Primonial</router-link>
            <slot></slot>
        </div>
        <slot name="deco"></slot>
    </header>

</template>

<script>
    export default {
        name: "Header",
    }
</script>
