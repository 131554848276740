<template>


    <div class="testimonies-slider">
        <h4 class="testimonies-slider-title">Ils ont choisi d’épargner en ligne avec LINK</h4>
        <div class="testimonies-slider-control testimonies-slider-control--left" @click="previous">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                <polygon points="189 389 471 682 372 777 0 389 372 0 471 95 189 389"/>
            </svg>
        </div>
        <div class="testimonies-slider-control testimonies-slider-control--right" @click="next">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                <polygon points="282 389 0 682 99 777 471 389 99 0 0 95 282 389"/>
            </svg>
        </div>

        <div class="testimonies-slider-container">

            <div class="testimonies-item" v-for="item in items">
                <div>
                    <div class="testimonies-rating">
                        <svg class="star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.57 226.04"
                             v-for="n in item.stars">
                            <path d="M159.12,67.25l69,12c8,1,11,11,6,17l-49,49,10,69c1,8-7,14-15,11l-61-31-63,31c-7,3-16-3-15-11l11-68-49-50c-6-6-3-16,6-17l68-12,32-62c4-7,15-7,18,0Z"
                                  style="fill-rule:evenodd"/>
                        </svg>
                    </div>
                    <p class="testimonies-body" v-html="item.body"></p>
                </div>
                <div class="testimonies-info">
                    <!--
                    <div class="testimonies-picture">
                        <img v-if="item.thumbnails" :src="`${item.thumbnails}`" alt="">
                    </div>
                    -->
                    <div class="testimonies-info-content">
                        <p class="testimonies-name" v-html="item.name"></p>
                        <p class="testimonies-job" v-html="item.job"></p>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "TestimoniesSlider",
        props: ['items'],
        data() {
            return {
                flkty: null,
            }
        },
        mounted() {
            let elem = document.querySelector('.testimonies-slider-container')
            this.flkty = new this.$flickity(elem, {
                cellAlign: 'left',
                contain: true,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true,
                bgLazyLoad: true,
            })
        },
        methods: {
            previous() {
                this.flkty.previous()
            },
            next() {
                this.flkty.next()
            },
        }
    }
</script>
