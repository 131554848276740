<template>

  <div id="app">
    <transition v-on:enter="mainMenuEnter" v-on:leave="mainMenuLeave">
      <main-menu v-if="menu"></main-menu>
    </transition>
    <loading ref="loading"></loading>
    <navigation></navigation>

    <div id="view">
      <router-view :key="$route.fullPath"></router-view>
    </div>

    <back-to-top></back-to-top>

    <link-footer></link-footer>

  </div>

</template>

<script>

import Keycloak from 'keycloak-js' // v 4.8.3

export default {
  name: "App",
  data() {
    return {
      keycloak: null,
      settings: null,
      posts_category: [],
      posts_categories: [],
      posts: [],
      sliders: [],
      profiles: [],
      products: [],
      online: true,
      menu: false,
      euros: null,
      objectif: null,
      contact: false,
    }
  },
  watch: {
    menu() {
      if (this.menu)
        document.querySelector('body').style.overflow = 'hidden'
      else
        document.querySelector('body').removeAttribute('style')
    },
  },
  created() {
    axios.get('/api/settings')
        .then(res => {
          this.settings = res.data;
          this.settings.quote_video_block = this.settings.quote_video_block.split("\n");
          this.settings.points_1_body = this.parseList(this.settings.points_1_body)
          this.settings.end_blocks = this.parseList(this.settings.end_blocks)
        })

    axios.get('/api/profiles')
        .then(res => {
          this.profiles = res.data;
        })

    axios.get('/api/products')
        .then(res => {
          this.products = res.data;
        })

    axios.get('/api/categories')
        .then(res => {
          res.data.forEach(el => {
            if (el.posts.length)
              this.posts_categories.push(el)
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const parrainId = urlParams.get('parrain');

    if (parrainId) window.sessionStorage.setItem('parrainId', parrainId);
    let parrain = window.sessionStorage.getItem('parrainId');

    const hash = window.location.hash;

    if (hash === '#contact') this.contact = true;

    if (KEYCLOAK_URL === "") {
      console.error("KEYCLOAK_URL not defined");
    }

    if (KEYCLOACK_REDIRECT === "") {
      console.error("KEYCLOACK_REDIRECT not defined");
    }

    if (KEYCLOACK_ID === "") {
      console.error("KEYCLOACK_ID not defined");
    }

    const config = {
      "keycloackAuthUrl": KEYCLOAK_URL,
      "keycloackClientId": KEYCLOACK_ID,
    }

    const keycloakConfig = {
      "realm": "REALM-PRIMONIAL-LINK",
      "url": config.keycloackAuthUrl,
      "ssl-required": "external",
      "clientId": config.keycloackClientId,
      "confidential-port": 0,
      "public-client": true
    }

    this.keycloak = new Keycloak(keycloakConfig);

    this.keycloak.init().success(authentification => {
      console.log('authentification :', authentification)
    }, error => {
      console.log('erreur :', error)
    })

    window.addEventListener('online', () => {
      this.online = true;
    });
    window.addEventListener('offline', () => {
      this.online = false;
    });

    document.addEventListener('click', (e) => {

      if (
          e.target.closest('.button') || e.target.closest('a[href]') ||
          e.target.closest('.offers-slider-button') || e.target.closest('.header-control') ||
          e.target.closest('.last-news-control')
      ) {

        let target = e.target.closest('div, a');

        let ink = document.createElement('div');
        //const d = Math.max(target.offsetHeight, target.offsetWidth);

        let d = 75;

        ink.style.width = `${d}px`;
        ink.style.height = `${d}px`;


        ink.style.left = `${e.offsetX - d / 2}px`
        ink.style.top = `${e.offsetY - d / 2}px`
        ink.classList.add('ink');

        target.appendChild(ink);

        setTimeout(() => {
          target.removeChild(ink);
        }, 650)
      }


    })

  },
  methods: {
    subscribe() {
      window.open(this.keycloak.createRegisterUrl({"redirectUri": KEYCLOACK_REDIRECT}), '_blank');
    },
    login() {
      window.open(this.keycloak.createLoginUrl({"redirectUri": KEYCLOACK_REDIRECT}), '_blank');
    },
    logout() {
      window.location.href = this.keycloak.createLogoutUrl({"redirectUri": APP_URL});
    },
    isRootUrl(url) {
      if (url)
        return url.substring(0, 1) === '/' ? true : false

      return false;
    },
    parseList(item) {

      item = item.split('##');

      if (!item.length)
        return null;

      let points = item.map(el => el.trim());

      let parsed = []

      points.forEach(el => {
        let array = el.split("\n")
        array[0] = array[0].replace(/\[/, '').replace(/\]/, '');
        array[1] = array[1];
        array[2] = el.split("***")[1];
        parsed.push(array);
      })

      return parsed;
    },
    mainMenuEnter(el, done) {

      this.$anime({
        targets: el,
        opacity: [0, 1],
        easing: 'spring(1, 100, 80, 0)',
      })

      done()

    },
    mainMenuLeave(el, done) {

      this.$anime({
        targets: el,
        opacity: 0,
        duration: 250,
        easing: 'spring(1, 100, 80, 0)',
        complete: () => {
          done()
        }
      })

    }
  }
}
</script>

