<template>

    <div class="posts-item" :class="{ 'posts-item--double' : index === 0}"
         @mouseenter="postMouseEnter($event)" @mouseleave="postMouseLeave()">
        <template v-if="!twitter">
            <div class="posts-item--image">

                <div class="posts-item--image-angle"></div>
                <div class="posts-item--image-src" :style="{ 'backgroundImage' : `url(/storage/${post.image})`}">
                    <img :src="`/storage/${post.image}`" alt="">
                </div>
                <router-link :to="`/actualites/${post.slug}`" class="posts-item--image-cta">
                    Découvrir
                </router-link>

                <div class="posts-item--image-angle"></div>

            </div>
            <div class="posts-item--content">
                <div class="posts-item--tag" v-if="post.category" :class="{ 'posts-item--tag--secondary' : post.category.slug === 'epargne'}"><span class="icon" :class="`icon--${post.category.slug}`"></span>
                    {{ post.category.name }}
                </div>
                <p class="posts-item--date">{{ post.created_at }}</p>
                <router-link :to="`/actualites/${post.slug}`" class="posts-item--title">{{ post.title }}</router-link>
                <p class="posts-item--excerpt" v-html="post.excerpt.substring(0, 100) + '...'"></p>
            </div>
        </template>
        <tw-block v-else></tw-block>
    </div>

</template>

<script>
    export default {
        name: "Post",
        props: ['post', 'index', 'twitter'],
        data() {
            return {
                itemEnter: null,
                enterTimeline: null,
                leaveTimeline: null,
            }
        },
        components: {
            twBlock: require('../components/TwitterBlock.vue').default
        },
        methods: {
            postMouseEnter(event) {

                this.leaveTimeline ? this.leaveTimeline.pause() : null;

                this.itemEnter = event.target;

                this.enterTimeline = this.$anime.timeline({
                    duration: 300,
                    easing: 'easeInOutQuad',
                })

                this.enterTimeline
                    .add({
                        targets: event.target.querySelector('.posts-item--image-angle:first-child'),
                        left: '50%',
                        top: '50%',
                        translateX: '-50%',
                        translateY: '-100%',
                    })
                    .add({
                        targets: event.target.querySelector('.posts-item--image-angle:last-child'),
                        right: '50%',
                        bottom: '50%',
                        translateX: '50%',
                        translateY: '100%',
                    }, '-=300')
                    .add({
                        targets: event.target.querySelector('.posts-item--image-src'),
                        scale: 1.1,
                    }, '-=300')
                    .add({
                        targets: event.target.querySelectorAll('.posts-item--image-angle'),
                        width: '20rem',
                    })
                    .add({
                        targets: event.target.querySelector('.posts-item--image-cta'),
                        opacity: 1,
                    })

            },
            postMouseLeave() {

                this.enterTimeline.pause();

                this.leaveTimeline = this.$anime.timeline({
                    duration: 300,
                    easing: 'easeInOutQuad',
                })

                this.leaveTimeline
                    .add({
                        targets: this.itemEnter.querySelector('.posts-item--image-cta'),
                        opacity: 0,
                    })
                    .add({
                        targets: this.itemEnter.querySelectorAll('.posts-item--image-angle'),
                        width: '6rem',
                    })
                    .add({
                        targets: this.itemEnter.querySelector('.posts-item--image-angle:first-child'),
                        left: '0%',
                        top: '0%',
                        translateX: '0%',
                        translateY: '0%',
                    })
                    .add({
                        targets: this.itemEnter.querySelector('.posts-item--image-angle:last-child'),
                        right: '0%',
                        bottom: '0%',
                        translateX: '0%',
                        translateY: '0%',
                    }, '-=300')
                    .add({
                        targets: event.target.querySelector('.posts-item--image-src'),
                        scale: 1
                    }, '-=300')


            }
        }
    }
</script>

<style scoped>

</style>
