<template>

    <div class="single">

        <template v-if="item">
            <link-header>
                <div class="single-header" v-if="item" :style="{ backgroundImage: `url(/storage/${item.image})`}"
                     @mousemove="postMouseMove($event)">
                    <div v-if="item.category" class="posts-item--tag">{{ item.category.name }}</div>
                    <h1 class="single-content--title">{{ item.title }}</h1>
                </div>
            </link-header>
            <div class="container" v-if="item">

                <breadcrumb
                        :items="[{text: 'Accueil', url: '/'}, {text: 'Toutes les actualités', url: '/actualites'},  { text: item.title } ]"></breadcrumb>

                <div class="single-container">
                    <aside class="single-aside"
                           v-if="(item.documents && JSON.parse(item.documents).length) || item.liens">
                        <div class="single-aside--title" v-if="item.documents">Documents</div>

                        <a target="_blank" class="single-aside--document" v-for="file in JSON.parse(item.documents)"
                           :href="getFile(file).file">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.97 168.66">
                                <path d="M3.6,0A3.91,3.91,0,0,0,0,3.6V151.4A3.75,3.75,0,0,0,3.6,155H108.14a3.74,3.74,0,0,0,3.6-3.6V36.05a3.64,3.64,0,0,0-1.07-2.59L78.23,1A3.66,3.66,0,0,0,75.7,0ZM7.21,7.21H72.09V36.05a3.75,3.75,0,0,0,3.61,3.6h28.83V147.79H7.21ZM79.3,12.28,99.47,32.44H79.3Zm-55.87,49a3.61,3.61,0,0,0,0,7.21H88.31a3.61,3.61,0,1,0,0-7.21Zm0,28.84a3.61,3.61,0,0,0,0,7.21H88.31a3.61,3.61,0,1,0,0-7.21Zm0,28.83a3.61,3.61,0,0,0,0,7.21H88.31a3.61,3.61,0,1,0,0-7.21Z"/>
                                <circle cx="99.47" cy="126.16" r="42.5"/>
                                <path class="dl"
                                      d="M114.67,139.2a2.17,2.17,0,0,1-2.17,2.17H86.43a2.17,2.17,0,1,1,0-4.34H112.5A2.17,2.17,0,0,1,114.67,139.2Zm-16.75-7.15a2.18,2.18,0,0,0,.7.46,2.16,2.16,0,0,0,1.69,0,2.18,2.18,0,0,0,.7-.46l7.67-7.69a2.17,2.17,0,0,0-2.83-3.3,2.72,2.72,0,0,0-.24.24l-4,4V113.13a2.18,2.18,0,0,0-4.35,0v12.14l-4-4a2.17,2.17,0,0,0-3.07,3.06Z"/>
                            </svg>
                            {{ getFile(file).name }}
                        </a>
                        <div class="single-aside--title" v-if="item.liens">Liens</div>
                        <a target="_blank" :href="link.url" class="single-aside--link" v-for="link in item.liens">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.97 54.97">
                                <path d="M51.17,3.8a13,13,0,0,0-18.38,0L23.6,13a2,2,0,1,0,2.82,2.83l9.2-9.19A9,9,0,0,1,48.34,19.36l-12,12a9,9,0,0,1-12.73,0,2,2,0,0,0-2.83,2.83,13,13,0,0,0,18.38,0l12-12a13,13,0,0,0,0-18.39Z"
                                      transform="translate(0 0)"/>
                                <path d="M27.13,40.57l-7.78,7.78A9,9,0,0,1,6.63,35.62L17.94,24.31a9,9,0,0,1,12.73,0,2,2,0,0,0,2.82-2.83,13,13,0,0,0-18.38,0L3.8,32.79A13,13,0,0,0,13,55a12.92,12.92,0,0,0,9.19-3.79L30,43.4a2,2,0,1,0-2.83-2.83Z"
                                      transform="translate(0 0)"/>
                            </svg>

                            {{ link.string }}
                        </a>
                    </aside>
                    <section class="single-content">

                        <p>{{ item.excerpt }}</p>

                        <div v-if="item.body">
                            <template v-for="block in JSON.parse(item.body).blocks">

                                <p v-if="block.type === 'paragraph'" v-html="block.data.text"></p>
                                <h2 v-if="block.type === 'header' && block.data.level === 2"
                                    v-html="block.data.text"></h2>
                                <h3 v-if="block.type === 'header' && block.data.level === 3"
                                    v-html="block.data.text"></h3>
                                <h4 v-if="block.type === 'header' && block.data.level === 4"
                                    v-html="block.data.text"></h4>
                                <img class="single-image" v-if="block.type === 'image'" :src="block.data.file.url"
                                     :alt="block.data.file.caption">

                                <div class="youtube-container"
                                     v-if="block.type === 'embed' && block.data.service === 'youtube'">
                                    <iframe class="youtube-iframe" :src="block.data.embed" frameborder="0"></iframe>
                                </div>


                            </template>
                        </div>

                        <div class="single-next" v-if="item.next">
                            <div class="single-next--title">Article suivant
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 471 777">
                                    <polygon points="282 389 0 682 99 777 471 389 99 0 0 95 282 389"
                                             style="fill-rule:evenodd"/>
                                </svg>
                            </div>
                            <router-link class="single-next--link" :to="`/actualites/${item.next.slug}`">{{
                                item.next.title }}
                            </router-link>
                        </div>

                        <router-link to="/actualites/" class="button button-all-posts">Toutes les actualités</router-link>

                    </section>



                </div>


            </div>
        </template>
    </div>

</template>

<style lang="scss" scoped>
    .button-all-posts {
        align-items: flex-start;
        justify-self: flex-start;
        margin-top: 50px;
    }
</style>

<script>
    export default {
        name: "Single",
        data() {
            return {
                item: null,
            }
        },
        mounted() {

            this.item = this.$root.posts.find(el => {
                return el.slug === this.$route.params.slug
            })

            if (this.item) {
                this.item.liens = this.linkParse(this.item.liens);
                document.title = `${this.item.title} | ${this.$root.settings.site_title}`
            }

            if (!this.item)
                axios.get(`/api/posts/${this.$route.params.slug}`)
                    .then(res => {
                        this.item = res.data;
                        this.item.liens = this.linkParse(this.item.liens);
                        document.title = `${this.item.title} | ${this.$root.settings.site_title}`
                    })
                    .catch(err => {
                        console.log(err.response)
                    })


        },
        methods: {
            getFile(file) {
                return {
                    name: file.replace('posts/', '').replace(/\.([a-z]*)/, '').replace(/_([a-zA-Z])/gi, ' $1'),
                    file: '/storage/' + file,
                }
            },
            linkParse(links) {

                if (!links)
                    return null;

                let array = links.split("\n");

                let json = [];

                array.forEach(el => {
                    json.push({
                        string: el.split(',')[0],
                        url: el.split(',')[1]
                    })
                })

                return json;
            },
            postMouseMove(event) {

                let x = 0,
                    y = 0,
                    ratio = 3

                let mx = event.offsetX,
                    my = event.offsetY

                let header = document.querySelector('.single-header'),
                    hx = header.clientWidth,
                    hy = header.clientHeight

                let rx = mx / hx * ratio + x - ratio / 2,
                    ry = my / hy * ratio + y - ratio / 2

                header.style.transform = `rotateX(${ry}deg) rotateY(${rx}deg)`
            },
        }
    }
</script>
