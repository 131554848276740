<template>

    <div class="posts">

        <link-header>
            <h1 class="header-text" v-if="$root.settings && $root.settings.posts_title" v-html="$root.settings.posts_title">
                Découvrez les dernières actualités de l’épargne en ligne.
            </h1>
        </link-header>

        <div class="container">

            <breadcrumb :items="[{text: 'Accueil', url: '/'}, { text: 'Actualités'} ]"></breadcrumb>


            <div class="posts-filter">
                <div class="posts-filter-item" v-for="category in $root.posts_categories"
                     :class="{ 'posts-filter-item--active' : $root.posts_category.includes(category.slug)}"
                     @click="filterPostCategory(category.slug)">
                    <span class="icon" :class="`icon--${category.slug}`"></span>
                    <p class="posts-filter-item--name">{{ category.name }}</p>
                </div>
            </div>

            <div class="posts-container">
                <post :key="index" v-for="(post, index) in posts.slice(0, 1)" :post="post" :index="index" v-show="showPost(post)"></post>
                <post :twitter="true"></post>
                <post :key="index + 1" v-for="(post, index) in posts.slice(1, 99)" :post="post" v-show="showPost(post)"
                      :index="index + 1"></post>
            </div>
            <transition name="fade">
                <img v-if="loading" class="posts-item--loader" :src="require('../../images/oval.svg')" width="50"
                     alt="">
            </transition>
        </div>
    </div>

</template>

<script>

    export default {
        name: "Posts",
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            settings() {
                return this.$root.settings;
            },
            posts() {
                return this.$root.posts;
            }
        },
        components: {
            post: require('../components/Post.vue').default,
        },
        methods: {
            showPost(post) {
                if (this.$root.posts_category.length)
                    return !post.id || (post.category && this.$root.posts_category.includes(post.category.slug))

                return true;
            },
            fetch() {
                this.loading = true;

                axios.post('/api/posts', {items: this.$root.posts})
                    .then(res => {

                        setTimeout(() => {
                            res.data.forEach(el => {
                                this.$root.posts.push(el)
                            })

                            this.loading = false;
                        }, 1000)


                    })
                    .catch(err => {
                        console.log(err.response)
                    })

            },
            filterPostCategory(index) {
                if (this.$root.posts_category.includes(index)) {

                    let id = this.$root.posts_category.findIndex(el => {
                        return el === index
                    })

                    this.$root.posts_category.splice(id, 1)

                } else {
                    this.$root.posts_category.push(index);
                }

            }
        },
        watch: {
            settings() {
                document.title = `Les actualités | ${this.$root.settings.site_title}`
            },
        },
        mounted() {
            this.fetch();
        },
    }
</script>
