<template>

    <div class="homepage">

        <link-header class="header-homepage">

            <template v-slot:deco>
                <div class="header-slider-loading" v-if="!loaded">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="160px" height="20px"
                         viewBox="0 0 128 16"
                         xml:space="preserve"><path fill="#ffffff" d="M6.4,4.8A3.2,3.2,0,1,1,3.2,8,3.2,3.2,0,0,1,6.4,4.8Zm12.8,0A3.2,3.2,0,1,1,16,8,3.2,3.2,0,0,1,19.2,4.8ZM32,4.8A3.2,3.2,0,1,1,28.8,8,3.2,3.2,0,0,1,32,4.8Zm12.8,0A3.2,3.2,0,1,1,41.6,8,3.2,3.2,0,0,1,44.8,4.8Zm12.8,0A3.2,3.2,0,1,1,54.4,8,3.2,3.2,0,0,1,57.6,4.8Zm12.8,0A3.2,3.2,0,1,1,67.2,8,3.2,3.2,0,0,1,70.4,4.8Zm12.8,0A3.2,3.2,0,1,1,80,8,3.2,3.2,0,0,1,83.2,4.8ZM96,4.8A3.2,3.2,0,1,1,92.8,8,3.2,3.2,0,0,1,96,4.8Zm12.8,0A3.2,3.2,0,1,1,105.6,8,3.2,3.2,0,0,1,108.8,4.8Zm12.8,0A3.2,3.2,0,1,1,118.4,8,3.2,3.2,0,0,1,121.6,4.8Z"/>
                        <g><path fill="#ffffff" d="M-42.7,3.84A4.16,4.16,0,0,1-38.54,8a4.16,4.16,0,0,1-4.16,4.16A4.16,4.16,0,0,1-46.86,8,4.16,4.16,0,0,1-42.7,3.84Zm12.8-.64A4.8,4.8,0,0,1-25.1,8a4.8,4.8,0,0,1-4.8,4.8A4.8,4.8,0,0,1-34.7,8,4.8,4.8,0,0,1-29.9,3.2Zm12.8-.64A5.44,5.44,0,0,1-11.66,8a5.44,5.44,0,0,1-5.44,5.44A5.44,5.44,0,0,1-22.54,8,5.44,5.44,0,0,1-17.1,2.56Z"/>
                            <animateTransform attributeName="transform" type="translate"
                                              values="23 0;36 0;49 0;62 0;74.5 0;87.5 0;100 0;113 0;125.5 0;138.5 0;151.5 0;164.5 0;178 0"
                                              calcMode="discrete" dur="1170ms" repeatCount="indefinite"/></g></svg>

                </div>
                <div class="header-slider" v-if="loaded">
                    <div class="header-slider-item" v-for="item in $root.sliders"
                         :style="{ backgroundImage: item.image ? `url(/storage/${item.image})` : 'none' }">
                        <div class="container">
                            <h1 class="header-title" v-html="item.title"></h1>

                            <p class="header-baseline" v-html="item.body"></p>

                            <div class="header-buttons" v-if="item.button_1_text || item.button_2_text">

                                <template v-if="item.button_1_text">
                                    <template v-if="$root.isRootUrl(item.button_1_url)">
                                        <router-link :to="item.button_1_url" class="header-buttons-item"
                                                     v-html="item.button_1_text"></router-link>
                                    </template>
                                    <template v-else>
                                        <a :href="item.button_1_url" target="_blank" v-html="item.button_1_text"
                                           class="header-buttons-item"></a>
                                    </template>
                                </template>

                                <template v-if="item.button_2_text">
                                    <template v-if="$root.isRootUrl(item.button_2_url)">
                                        <router-link :to="item.button_2_url" class="header-buttons-item"
                                                     v-html="item.button_2_text"></router-link>
                                    </template>
                                    <template v-else>
                                        <a :href="item.button_2_url" target="_blank" v-html="item.button_2_text"
                                           class="header-buttons-item"></a>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header-deco">
                </div>

                <promo v-if="settings && settings.promotion" :promo="settings.promotion"></promo>

                <div v-if="$root.sliders.length > 1" class="header-control header-control--left" @click="previous()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                        <polygon points="189 389 471 682 372 777 0 389 372 0 471 95 189 389"/>
                    </svg>
                </div>
                <div v-if="$root.sliders.length > 1" class="header-control header-control--right" @click="next()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 471 777">
                        <polygon points="282 389 0 682 99 777 471 389 99 0 0 95 282 389"/>
                    </svg>
                </div>


            </template>

        </link-header>


        <div class="homepage-block-video" v-if="settings" :style="{ paddingTop : settings.promotion ? '18rem' : null}">

            <div class="container">


                <div class="homepage-block-video--columns">

                    <div>
                        <h2 class="homepage-title homepage-title--main" v-html="settings.title_video_block"></h2>
                        <quotes :items="settings.quote_video_block"></quotes>
                        <p class="large" v-html="settings.desc_video_block"></p>
                    </div>

                    <div class="homepage-youtube">
                        <div class="video-container">
                            <iframe :src="`https://www.youtube-nocookie.com/embed/${settings.id_video}`" frameborder="0"
                                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                        </div>
                    </div>

                </div>
                <div class="homepage-block-video--columns homepage-block-video--columns--center">
                    <router-link to="/simulation" class="button">Je simule mon épargne</router-link>
                    <router-link to="/nos-offres/link-vie" class="button">Découvrez l'épargne en ligne LINK Vie</router-link>
                </div>

            </div>

        </div>


        <div class="container" v-if="settings">


            <h3 class="homepage-title homepage-title--secondary" v-html="settings.title_keyspoint"></h3>


            <div class="keypoints">
                <div class="keypoints-item" v-if="settings.keypoints_1">

                    <div class="keypoints-item--icon" v-if="settings.keypoints_1_icon" :style="{ backgroundImage: `url(/storage/${JSON.parse(settings.keypoints_1_icon)[0].download_link})`}"></div>
                    <p class="keypoints-item--title" v-html="settings.keypoints_1"></p>
                    <!--
                    <svg class="keypoints-item--more" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.2 490.2">
                        <path d="M418.5,418.5c95.6-95.6,95.6-251.2,0-346.8s-251.2-95.6-346.8,0-95.6,251.2,0,346.8S322.9,514.1,418.5,418.5ZM89,89C175.1,2.9,315.1,2.9,401.2,89s86.1,226.1,0,312.2-226.1,86.1-312.2,0S3,175.1,89,89Z"/>
                        <path d="M245.1,336.9a12.5,12.5,0,0,0,8.7-3.6,12.32,12.32,0,0,0,3.6-8.7V257.3h67.3a12.5,12.5,0,0,0,8.7-3.6A12.32,12.32,0,0,0,337,245a12.12,12.12,0,0,0-12.2-12.2H257.5V165.5a12.2,12.2,0,1,0-24.4,0v67.3H165.8a12.2,12.2,0,0,0,0,24.4h67.3v67.3A11.88,11.88,0,0,0,245.1,336.9Z"/>
                    </svg>
                    -->
                </div>
                <div class="keypoints-item" v-if="settings.keypoints_2">
                    <div class="keypoints-item--icon" v-if="settings.keypoints_2_icon" :style="{ backgroundImage: `url(/storage/${JSON.parse(settings.keypoints_2_icon)[0].download_link})`}"></div>
                    <p class="keypoints-item--title" v-html="settings.keypoints_2"></p>
                    <!--
                    <svg class="keypoints-item--more" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.2 490.2">
                        <path d="M418.5,418.5c95.6-95.6,95.6-251.2,0-346.8s-251.2-95.6-346.8,0-95.6,251.2,0,346.8S322.9,514.1,418.5,418.5ZM89,89C175.1,2.9,315.1,2.9,401.2,89s86.1,226.1,0,312.2-226.1,86.1-312.2,0S3,175.1,89,89Z"/>
                        <path d="M245.1,336.9a12.5,12.5,0,0,0,8.7-3.6,12.32,12.32,0,0,0,3.6-8.7V257.3h67.3a12.5,12.5,0,0,0,8.7-3.6A12.32,12.32,0,0,0,337,245a12.12,12.12,0,0,0-12.2-12.2H257.5V165.5a12.2,12.2,0,1,0-24.4,0v67.3H165.8a12.2,12.2,0,0,0,0,24.4h67.3v67.3A11.88,11.88,0,0,0,245.1,336.9Z"/>
                    </svg>
                    -->
                </div>
                <div class="keypoints-item" v-if="settings.keypoints_3">
                    <div class="keypoints-item--icon" v-if="settings.keypoints_3_icon" :style="{ backgroundImage: `url(/storage/${JSON.parse(settings.keypoints_3_icon)[0].download_link})`}"></div>
                    <p class="keypoints-item--title" v-html="settings.keypoints_3"></p>
                    <!--
                    <svg class="keypoints-item--more" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.2 490.2">
                        <path d="M418.5,418.5c95.6-95.6,95.6-251.2,0-346.8s-251.2-95.6-346.8,0-95.6,251.2,0,346.8S322.9,514.1,418.5,418.5ZM89,89C175.1,2.9,315.1,2.9,401.2,89s86.1,226.1,0,312.2-226.1,86.1-312.2,0S3,175.1,89,89Z"/>
                        <path d="M245.1,336.9a12.5,12.5,0,0,0,8.7-3.6,12.32,12.32,0,0,0,3.6-8.7V257.3h67.3a12.5,12.5,0,0,0,8.7-3.6A12.32,12.32,0,0,0,337,245a12.12,12.12,0,0,0-12.2-12.2H257.5V165.5a12.2,12.2,0,1,0-24.4,0v67.3H165.8a12.2,12.2,0,0,0,0,24.4h67.3v67.3A11.88,11.88,0,0,0,245.1,336.9Z"/>
                    </svg>
                    -->
                </div>
            </div>


            <points :icon="JSON.parse(settings.points_1_icon)[0].download_link"
                    :title='settings.points_1.split("\n")[0]'
                    :image="settings.points_1_image"
                    :body='settings.points_1.split("\n")[1]'></points>

            <points reverse
                    :icon="JSON.parse(settings.points_2_icon)[0].download_link"
                    :title='settings.points_2.split("\n")[0]'
                    :image="settings.points_2_image"
                    :body='settings.points_2.split("\n")[1]'></points>

        </div>

        <offers-slider></offers-slider>

        <div class="container">

            <h3 class="homepage-title">
                Ils ont choisi d’épargner en ligne avec LINK
            </h3>

            <testimonies :limit="3"></testimonies>

            <last-news title="Mieux comprendre les solutions LINK by Primonial"></last-news>

        </div>


        <div class="homepage-reference" v-if="settings">
            <div class="container">
                <p class="homepage-reference-title" v-html="settings.reference_title"></p>
                <p class="homepage-reference-body" v-html="settings.reference_body"></p>


                <div class="homepage-reference-items">
                    <div v-for="(item, index) in references" class="homepage-reference-item" :style="{ backgroundImage : `url(/storage/${item.logo})` }" @click="reference = index">
                        <img :src="`/storage/${item.logo}`" alt="" width="180">
                    </div>
                </div>

                <transition-group v-on:enter="onEnter" v-on:leave="onLeave">
                    <reference-item v-for="(item, index) in references" :key="`reference__${index}`" :logo="item.logo" :url="item.url" v-show="reference === index">
                        <div v-html="item.description"></div>
                    </reference-item>

                </transition-group>

            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "Homepage",
        data() {
            return {
                flkty: null,
                loaded: false,
                reference: null,
                references: [],
                elem: null,
            }
        },
        computed: {
            settings() {
                return this.$root.settings;
            }
        },
        watch: {
            settings() {
                document.title = this.settings && this.settings.site_title ? `Accueil | ${this.settings.site_title}` : 'Accueil'
            },
            '$root.online'() {
                if (this.$root.online)
                    this.init();
            },
            elem() {
                this.flkty = new this.$flickity(this.elem, {
                    cellAlign: 'left',
                    contain: true,
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                    bgLazyLoad: true,
                })
            }
        },
        created() {
            axios.get('/api/sliders')
                .then(res => {
                    this.$root.sliders = res.data;
                    this.loaded = true
                })

            axios.get('/api/references')
                .then(res => {
                    this.references = res.data;
                })
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {

                let interval = setInterval(() => {
                    let elem = document.querySelector('.header-slider');

                    if(elem) {
                        this.elem = elem;
                        clearInterval(interval);
                    }
                }, 100)
            },
            previous() {
                this.flkty.previous()
            },
            next() {
                this.flkty.next()
            },
            onEnter(el, done) {

                this.$anime({
                    targets: el,
                    opacity: [0, 1],
                    duration: 300,
                    easing: 'linear',
                })

                this.$anime({
                    targets: el.querySelector('.reference-item-container'),
                    translateY: ['200%', '-50%'],
                    translateX: '-50%',
                    easing: 'spring(1, 100, 30 0)',
                    complete: () => {
                        done()
                    }
                })

            },
            onLeave(el, done) {

                this.$anime({
                    targets: el,
                    opacity: 0,
                    duration: 300,
                    easing: 'linear',
                    complete: () => {
                        done()
                    }
                })

                this.$anime({
                    targets: el.querySelector('.reference-item-container'),
                    translateY: '200%',
                    translateX: '-50%',
                    easing: 'spring(1, 100, 30 0)',
                })

            },
        }
    }
</script>
