<template>
    <div>
        <link-header>
            <h1 class="header-text">
                404 - La page demandée n'existe pas, ou a changé de nom
            </h1>
        </link-header>

        <div class="container">
            <breadcrumb :items="[{text: 'Accueil', url: '/'}, { text: 'Page non trouvée'} ]"></breadcrumb>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>

</style>
