<template>

    <div class="accordion" :class="'accordion_' + _uid" :style="{ zIndex: index }">
        <div class="accordion-icon" :class="{ 'active' : active }" @click="toggle($event)"></div>
        <div class="accordion-button" :class="{ 'active' : active }" @click="toggle($event)">{{ button }}</div>
        <div class="accordion-panel">
            <p class="accordion-content " v-html="parsedContent"></p>
            <div v-if="more" v-show="showMore" class="accordion-more accordion-content">
                <div class="accordion-more-close" @click="showMore = false"></div>
                <p v-html="more"></p>
            </div>
        </div>


    </div>

</template>

<style>
    .showMore {
        text-decoration: underline dotted;
        cursor: help;
        z-index: 20;
    }
</style>

<script>
    export default {
        name: "Accordion",
        props: ['button', 'content', 'more'],
        data() {
            return {
                active: false,
                showMore: false,
                index: 1,
            }
        },
        created() {
            document.addEventListener('click', this.toggleShowMore)
        },
        destroyed() {
            document.removeEventListener('click', this.toggleShowMore)
        },
        computed: {
            parsedContent() {

                if (this.more) {
                    let special = /\*\*(\w+)\*\*/ig;
                    return this.content.replace(special, `<span class="showMore">$1</span>`);
                }

                return this.content;
            }
        },
        methods: {
            toggleShowMore(event) {
                if (event.target === document.querySelector('.accordion_' + this._uid + ' .showMore'))
                    this.showMore = !this.showMore;

                if (this.showMore)
                    this.index = 10
                else
                    this.index = 1

            },
            toggle(event) {
                let panel = event.target.parentNode.querySelector('.accordion-panel');

                this.active = !this.active;

                if (this.active)
                    this.$anime({
                        targets: panel,
                        maxHeight: panel.scrollHeight,
                        easing: 'easeInOutQuad',
                        duration: 500,
                    });
                else
                    this.$anime({
                        targets: panel,
                        maxHeight: 0,
                        easing: 'easeInOutQuad',
                        duration: 500,
                    });

            }
        }
    }
</script>
