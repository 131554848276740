<template>
    <footer class="footer">

        <div class="container">
            <p class="footer-body">
                LINK VIE est un contrat d’assurance vie individuel de type multisupport commercialisé par LINK by
                Primonial en sa qualité d’intermédiaire en assurances, et assuré par Oradéa Vie (groupe Société
                Générale), entreprise régie par le code des assurances. Un contrat multisupport présente un risque de
                perte en capital pour la partie investie en unités de compte.
            </p>
        </div>

        <div class="container">
            <router-link to="/" class="footer-logo"></router-link>
            <div class="footer-content">
                <p class="footer-tw" v-if="$root.settings && $root.settings.twitter_url">
                    Retrouvez l'actualité LINK sur <a :href="$root.settings && $root.settings.twitter_url"
                                                      target="_blank" rel="nofollow"><span></span> Twitter</a>
                </p>
                <ul class="footer-list">
                    <li class="footer-list-item">
                        <router-link to="/mentions-legales" class="footer-list-item--link">Mentions légales
                        </router-link>
                    </li>
                    <li class="footer-list-item">
                        <router-link to="/politique-de-donnees-personnelles" class="footer-list-item--link">Politique de
                            données personnelles
                        </router-link>
                    </li>
                    <li class="footer-list-item">
                        <router-link to="/conditions-generales" class="footer-list-item--link">Conditions générales
                        </router-link>
                    </li>
                    <li class="footer-list-item">
                        <router-link to="/faq" class="footer-list-item--link">F.A.Q</router-link>
                    </li>
                    <li class="footer-list-item">
                        <router-link to="/lexique" class="footer-list-item--link">Lexique</router-link>
                    </li>
                    <li class="footer-list-item">
                        <router-link to="/contact" class="footer-list-item--link">Contact</router-link>
                    </li>
                </ul>
            </div>
        </div>

    </footer>
</template>

<script>
    export default {
        name: "Footer",
    }
</script>
