<template>

    <div class="page">

        <div class="page-container" v-if="item">

            <link-header>
                <h1 class="header-text">
                    {{ item.title }}
                </h1>
            </link-header>

            <div class="container">

                <breadcrumb :items="[{text: 'Accueil', url: '/'}, { text: item.title} ]"></breadcrumb>

                <div class="page-body" v-html="item.body"></div>
            </div>

        </div>

        <div v-else>
            <not-found></not-found>
        </div>

    </div>

</template>

<script>
    export default {
        name: "Page",
        data() {
            return {
                item: null
            }
        },
        mounted() {

            axios.get('/api/pages/' + this.$route.params.slug)
                .then(res => {
                    this.item = res.data;
                    document.title = this.settings && this.settings.site_title ? `${this.item.title} | ${this.settings.site_title}` : this.item.title
                })
                .catch(err => {
                    if (err.response && err.response.status === 404)
                        this.item = null;
                })

        }
    }
</script>

<style scoped>
    .page-body {
        margin-top: 10rem;
    }
</style>
