<template>

    <div class="single-product">

        <link-header>
            <div class="single-product-header" v-if="item">
                <h1 class="single-product-header--title" v-html="item.title"></h1>
                <h2 class="single-product-header--body" v-html="item.slider_introduction"></h2>
            </div>


            <template v-slot:deco>


                <div class="single-product-buttons" v-if="item">
                    <router-link :to="'/nos-offres/' + item.previous" class="single-product-buttons-previous"
                                 v-if="item.previous">
                        <span>Offre</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 27.21">
                            <path d="M.87,11.31a2.91,2.91,0,0,0-.63,1,3,3,0,0,0,0,2.34,2.91,2.91,0,0,0,.63,1L11.49,26.16A3,3,0,1,0,16,22.26a3.34,3.34,0,0,0-.32-.33l-5.49-5.49H27a3,3,0,0,0,0-6H10.23L15.72,5A3,3,0,0,0,11.49.72Z"
                                  transform="translate(0 0)" style="fill:#6dbbc7"/>
                        </svg>
                        <span>Précédente</span>
                    </router-link>
                    <router-link :to="'/nos-offres/' + item.next" class="single-product-buttons-next" v-if="item.next">
                        <span>Offre</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 27.21">
                            <path d="M29.13,15.9a3.07,3.07,0,0,0,.63-1,3,3,0,0,0,0-2.34,3.07,3.07,0,0,0-.63-1L18.51,1.05A3,3,0,0,0,14,5a2.57,2.57,0,0,0,.33.33l5.49,5.49H3a3,3,0,0,0,0,6H19.77l-5.49,5.49a3,3,0,0,0,4.23,4.23Z"
                                  style="fill:#6dbbc7"/>
                        </svg>
                        <span>Suivante</span>
                    </router-link>
                </div>

                <div v-if="item" class="single-product-header-image"
                     :style="{ backgroundImage : item.image_product ? `url(/storage/${item.image_product})` : 'none' }"></div>
            </template>

        </link-header>

        <div class="single-product--color">

            <div class="container" v-if="item">

                <breadcrumb
                        :items="[{text: 'Accueil', url: '/'}, { text: 'Nos offres', url: '/nos-offres'}, { text: item.title } ]"></breadcrumb>


                <div class="single-product-body">

                    <h3 class="single-product-body--heading" v-html="item.body_heading"></h3>
                    <div class="single-product-body--introduction" v-html="item.body_introduction"></div>

                </div>


                <profils v-if="item" :item="item"></profils>


                <panels v-if="item.profiles" :item="item" :performances="performances"></panels>

                <router-link to="/simulation" class="button single-product-button">Je simule</router-link>

            </div>

        </div>

        <div class="container" v-if="item">

            <testimonies-slider v-if="item.testimonies.length"
                                :items="item.testimonies"></testimonies-slider>

            <last-news center></last-news>

            <div class="awards">
                <div class="testimonies-rating">
                    <svg class="star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.57 226.04"
                         v-for="n in 4">
                        <path d="M159.12,67.25l69,12c8,1,11,11,6,17l-49,49,10,69c1,8-7,14-15,11l-61-31-63,31c-7,3-16-3-15-11l11-68-49-50c-6-6-3-16,6-17l68-12,32-62c4-7,15-7,18,0Z"
                              style="fill-rule:evenodd"/>
                    </svg>
                </div>
                <h3 class="awards-title" v-if="item.rewards">
                    L'épargne en ligne {{ item.title }} récompensée
                </h3>
                <div class="awards-items" v-if="item.rewards">
                    <div class="awards-item" v-for="reward in item.rewards">
                        <img :src="reward.image ? `/storage/${reward.image}` : ''" :alt="reward.name">
                        <p class="awards-item-content">{{ reward.content }}</p>
                        <p class="awards-item-content">{{ reward.par }}</p>
                    </div>
                </div>
            </div>


        </div>

    </div>

</template>

<script>
    export default {
        name: "Product",
        data() {
            return {
                item: null,
            }
        },
        computed: {
            settings() {
                return this.$root.settings;
            },
            performances() {

                let perf = [];

                let profiles = JSON.parse(this.item.profiles);

                if (!profiles)
                    return null;

                for (let i = 0; i < profiles.length; i++) {
                    perf.push({id: profiles[i].profil_id, performances: []})
                }

                if(!this.item.performances)
                    return null;

                for (let p = 0; p < this.item.performances.length; p++) {
                    let performance = this.item.performances[p];

                    let pe = perf.find(el => {
                        return el.id == performance.profil_id
                    })

                    if (pe)
                        pe.performances.push({value: performance.valeur, date: performance.date});
                }

                return perf;
            },
        },
        mounted() {

            this.item = this.$root.products.find(el => {
                return el.slug === this.$route.params.slug
            })

            if (!this.item)
                axios.get(`/api/products/${this.$route.params.slug}`)
                    .then(res => {
                        this.item = res.data;
                        document.title = `Notre offre | ${this.item.title} | ${this.settings.site_title}`
                    })
                    .catch(err => {
                        console.log(err.response)
                    })
            else
                document.title = `Notre offre | ${this.item.title} | ${this.settings.site_title}`


        },
    }
</script>
