<template>

    <div class="perks">


        <link-header :style="{ backgroundImage: 'url('+ require(`../../images/background.jpg`)+')'}">
            <h1 v-if="settings" class="header-text header-text--left" v-html="settings.title_header"></h1>

            <template v-slot:deco>
                <div class="header-deco"></div>
            </template>

        </link-header>


        <div class="container" v-if="settings">

            <breadcrumb :items="[{text: 'Accueil', url: '/'}, { text: 'Les avantages'} ]"></breadcrumb>

            <h2 class="page-title" v-html="settings.title_page"></h2>


            <div class="perks-item perks-item--1" :id='slug(settings.points_1.split("\n")[0])'>

                <div class="perks-item--column">
                    <div>
                        <div class="points-icon"
                             :style="{ backgroundImage : `url(/storage/${JSON.parse(settings.points_1_icon)[0].download_link})`}"></div>
                        <div class="points-content">
                            <h3 class="points-title" v-html='settings.points_1.split("\n")[0]'></h3>
                            <p class="points-body" v-html='settings.points_1.split("\n")[1]'></p>
                        </div>
                    </div>
                    <div class="points-image">
                        <img :src="`/storage/${settings.points_1_image}`" alt="">
                    </div>
                </div>
                <div class="perks-item--column">
                    <accordion :key="`points__${index}`" v-for="(point, index) in settings.points_1_body"
                               :button="point[0]"
                               :content="point[1]"
                               :more="point[2] ? point[2] : null"></accordion>
                    <p class="perks-item-etf">
                        <strong>
                            Les <span @click="showMore = !showMore" class="help">ETF</span> utilisés dans les
                            allocations
                            sont des unités de compte et présentent un risque de
                            <span class="help" @click.prevent="showMore2 = !showMore2">perte en capital</span>.
                        </strong>
                    </p>

                    <div v-show="showMore" class="accordion-more accordion-content">
                        <div class="accordion-more-close" @click="showMore = false"></div>
                        Un ETF (Exchange Traded Fund) est un organisme de placement collectif ayant pour objectif de
                        répliquer un indice des marchés actions ou de taux. Ainsi, la performance de ce type de support
                        dépend de la variation à la hausse ou à la baisse de l’indice que l’ETF réplique. Les ETF sont
                        également cotés en bourse et peuvent s’échanger sur le marché tout au long de la journée de
                        cotation. Les ETF sont des unités de compte et présentent un risque de perte en capital. Se
                        référer à la Note d’information du contrat d’assurance vie pour tout renseignement
                        complémentaire.
                    </div>
                    <div v-show="showMore2" class="accordion-more accordion-content">
                        <div class="accordion-more-close" @click="showMore2 = false"></div>
                        l’assureur ne s’engage que sur le nombre d’unités de compte et non sur leur valeur. La valeur
                        des
                        unités de compte qui reflète la valeur d’actifs sous-jacents, n’est pas garantie mais est
                        sujette à
                        des fluctuations à la hausse comme à la baisse dépendant en particulier de l’évolution des
                        marchés
                        financiers. Le fonctionnement des unités de compte est décrit dans la Note d’Information du
                        contrat. 
                    </div>

                </div>

            </div>

            <div class="perks-item perks-item--2" :id='slug(settings.points_2.split("\n")[0])'>
                <div class="perks-item--column">
                    <div>
                        <div class="points-icon"
                             :style="{ backgroundImage : `url(/storage/${JSON.parse(settings.points_2_icon)[0].download_link})`}"></div>
                        <div class="points-content">
                            <h3 class="points-title" v-html='settings.points_2.split("\n")[0]'></h3>
                            <p class="points-body" v-html='settings.points_2.split("\n")[1]'></p>
                            <div class="perks-item-body" v-html="settings.points_2_body"></div>
                        </div>
                    </div>
                </div>
                <div class="perks-item--column">
                    <div class="points-image">
                        <img :src="`/storage/${settings.points_2_image}`" alt="">
                    </div>
                </div>
            </div>


            <div class="perks-container">

                <p class="perks-content" v-html="settings.end_page"></p>

                <div class="perks-blocks">
                    <div class="perks-blocks-item" v-for="blocks in settings.end_blocks">
                        <p class="perks-blocks-head" v-html="blocks[0]"></p>
                        <p class="perks-blocks-body" v-html="parsed(blocks[1])"></p>
                    </div>
                </div>
            </div>

        </div>
    </div>


</template>

<script>

    import slugify from 'slugify';

    export default {
        name: "Perks",
        data() {
            return {
                showMore: false,
                showMore2: false,
            }
        },
        computed: {
            settings() {
                return this.$root.settings;
            }
        },
        watch: {
            settings() {
                document.title = `Les avantages | ${this.settings.site_title}`
            }
        },
        methods: {
            slug(str) {
                return slugify(str.toLowerCase())
            },
            parsed(str) {
                let special = /\*\*([\w-é \(\)]+)\*\*/ig;
                return str.replace(special, `<span>$1</span>`);
            }
        }
    }
</script>
