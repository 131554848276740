<template>

  <div class="main-menu">

    <a href="#" class="main-menu-close" @click.prevent="$root.menu = false">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <polygon fill="white" stroke-width="3px" stroke="white"
                 points="58 0 30 28.1 1.9 0 0 1.9 28 30 0 58.1 1.9 60 30 31.9 58 60 60 58.1 31.9 30 60 1.9 58 0"/>
      </svg>

      Fermer
    </a>

    <div class="container">
      <router-link to="/" class="main-menu-logo"></router-link>
      <div class="main-menu-container">
        <div class="main-menu-column">
          <router-link class="main-menu-link" to="/">Accueil</router-link>
          <router-link class="main-menu-link" to="/les-avantages">Les avantages</router-link>
          <router-link class="main-menu-link main-menu-link--more" to="/nos-offres">Nos offres</router-link>
          <router-link class="main-menu-link main-menu-link--sub" :key="product.id"
                       :to="`/nos-offres/${product.slug}`"
                       v-for="product in $root.products">{{ product.title }}
          </router-link>
          <router-link class="main-menu-link main-menu-link--after" to="/actualites">Actualités</router-link>
        </div>
        <div class="main-menu-separation"></div>
        <div class="main-menu-column">
          <router-link class="main-menu-link main-menu-button" to="/simulation">Simuler</router-link>
          <a class="main-menu-link main-menu-button" v-if="$root.settings && $root.settings.space_client"
             :href="$root.settings.space_client" target="_blank">Votre espace client</a>
          <div>
            <a class="main-menu-link main-menu-button" target="_blank" @click.prevent="$root.subscribe()">Je
              souscris</a>
            <a class="main-menu-link main-menu-button" v-if="$root.settings && $root.settings.gerer"
               :href="$root.settings.gerer" target="_blank">Gérer mes souscriptions</a>
          </div>
          <a class="main-menu-link--tw" :href="$root.settings.twitter_url" target="_blank"
             v-if="$root.settings && $root.settings.twitter_url">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436 354.1">
              <path id="body"
                    d="M110.8,291.1c-1.6,1-3,1.7-4,2.3-3,1.5-7.9,3.8-14.9,6.9a152.38,152.38,0,0,1-23.1,7.9A204.17,204.17,0,0,1,47,312.9c-6.2.9-12.2,1.5-18.1,1.8a154.94,154.94,0,0,1-16.7,0c-5.2-.3-8.5-.5-9.6-.6l-1.8-.2-.4-.1-.4-.1v.8H.2l.1.1.1.1.1.1.1.1.1.1H.9l.4.2.4.2.1.1.1.1.1.1.1.1L5.7,318c2.4,1.5,4.5,2.6,6.4,3.6s6.6,3.3,14.1,7.1a252.74,252.74,0,0,0,27.2,11.1c10.6,3.7,18.6,6.2,24,7.4s12.8,2.6,22.2,3.9,14.9,2.1,16.3,2.2,3.3.3,5.5.4l3.3.2v.2h25.6v-.2l14-1.3c9.3-.9,17.6-2.1,25-3.3a197.49,197.49,0,0,0,22.8-5.5c7.9-2.4,15.3-4.9,22.4-7.7a171.52,171.52,0,0,0,19.7-9c6.1-3.2,11.3-6,15.6-8.5a169.34,169.34,0,0,0,14.2-9.3c5.2-3.7,10-7.5,14.6-11.2s7.1-5.9,7.7-6.4l10.2-10.2c6.2-6.3,11.3-11.9,15.4-16.8s8-10.3,12-15.9,8.3-12.5,13-20.6a243.36,243.36,0,0,0,13.5-27.5,260.65,260.65,0,0,0,11-31.5c3-10.7,5.2-20.4,6.7-28.9s2.4-16.5,3-23.8.8-13.9.9-19.7A18.85,18.85,0,0,1,399.4,82a183,183,0,0,0,15-13.7c5.1-5.1,7.9-8,8.3-8.7a4.41,4.41,0,0,1,1.1-1.2c.3-.1,2.1-2.3,5.3-6.7s5-6.6,5-6.7l.1-.1.2-.4.2-.4.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.2-.1.2-.1V42l-1,.3-.8.1-.4.2-.4.2-.4.2-.4.2-.6.2-1.2.4-10.6,3.6a214.47,214.47,0,0,1-21,5.7l-11,2.4h-1.9l.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.4-.2.4-.2.1-.1.1-.1.1-.1.1-.1.4-.2.4-.2,1.9-1.3a18.67,18.67,0,0,0,3.3-2.8,74.12,74.12,0,0,1,5.6-5.1,67.3,67.3,0,0,0,9.5-10.7,94.75,94.75,0,0,0,8.8-14c2.3-4.6,3.6-7.2,3.7-7.9s.3-1.2.4-1.6l.2-.6.2-.4.2-.4.2-.6.2-.6.1-.6.1-.6-.4.1-.4.1-.1.1-.1.1-.1.1-.1.1-.4.2-.4.2-.4.2-.4.2-.1.1c-.1.1-.8.4-1.9,1.2s-4.7,2.4-10.5,5-11.6,5-17.5,7.1A155.32,155.32,0,0,1,374.2,26c-5.1,1.2-8.8.5-11.3-1.9a64.64,64.64,0,0,0-8.3-6.9,98.49,98.49,0,0,0-10.4-6.4,97.53,97.53,0,0,0-11.8-5.5,71.52,71.52,0,0,0-13.5-3.8L311.5,0H291V.1a34.33,34.33,0,0,1-5.1.9,74.15,74.15,0,0,0-12.6,3.3A107.28,107.28,0,0,0,257,11.7a83.58,83.58,0,0,0-15.7,11.2,87,87,0,0,0-11.2,12.2,112,112,0,0,0-7,10.5C189.4,90.3,199.2,234,110.8,291.1Z"
                    style="fill:#fff"/>
              <path id="wing1"
                    d="M201.1,108.5q-7.5-.75-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5S147,96.7,136.9,93a222.45,222.45,0,0,1-28.3-12.8,262.4,262.4,0,0,1-24-14.4C77.4,60.9,71.5,56.7,67.1,53s-7.1-6-7.9-7a7.1,7.1,0,0,0-1.9-1.8c-.5-.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9-.1-.1-.1-.1L31,17l-.2-.4-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1V16l-.1.1-.1.1v.1l-.1.1-.2.6-.2.6L25.8,25a101.71,101.71,0,0,0-5.1,14.1,101.43,101.43,0,0,0-2.4,12,99.36,99.36,0,0,0-.4,12.8A78.74,78.74,0,0,0,19.7,79a85.43,85.43,0,0,0,4.6,15.2,129.28,129.28,0,0,0,5.8,12.5,57.61,57.61,0,0,0,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3a55.27,55.27,0,0,0,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l.1.1.4.2.4.2.1.1.1.1.1.1.1.1.4.2.4.2.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1H52.9l-1.8-.2c-1.2-.1-4.8-.9-10.8-2.2a77.44,77.44,0,0,1-15.1-4.7l-6.1-2.8-.4-.2-.4-.2-.4-.2-.4-.2-.4-.1-.4-.1.7,8.3a89.63,89.63,0,0,0,2.8,16.3,97,97,0,0,0,6.5,16.9,80.61,80.61,0,0,0,9.8,15.5,115.51,115.51,0,0,0,10.1,11,93.14,93.14,0,0,0,9.8,7.8,77.93,77.93,0,0,0,13.8,7.3c5.8,2.5,9.3,3.9,10.6,4.3s2.2.6,2.8.8l.8.2.8.2.8.2.8.2.8.2-.1.1-.1.1-.1.1-.1.1-1,.2-1,.2-.8.2c-.5.1-1.9.4-4.3.8a119.52,119.52,0,0,1-12.8.8,97.8,97.8,0,0,1-13.5-.4l-4.3-.6-.6-.1-.6-.1.1.4.1.4.2.6.2.6,3.2,7.2a114.06,114.06,0,0,0,6.7,12.7,73.44,73.44,0,0,0,8.3,10.7,100.61,100.61,0,0,0,7.9,8,73.69,73.69,0,0,0,9.9,7.2A95.14,95.14,0,0,0,99,270.1a87.64,87.64,0,0,0,13.5,4.7,82.18,82.18,0,0,0,9.2,1.8c1.9.1,4,.3,6.1.4l3.1.2c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-.1C209.5,109.3,206.1,108.9,201.1,108.5Z"
                    style="fill:#fff"/>
              <path id="wing2"
                    d="M201.1,108.5q-7.5-.75-22.2-3.6c-9.8-1.8-16.8-3.3-20.8-4.5S147,96.7,136.9,93a222.45,222.45,0,0,1-28.3-12.8,262.4,262.4,0,0,1-24-14.4C77.4,60.9,71.5,56.7,67.1,53s-7.1-6-7.9-7a7.1,7.1,0,0,0-1.9-1.8c-.5-.2-3.2-2.7-8-7.6s-9.1-9.2-12.6-13.2l-5.3-5.9-.1-.1-.1-.1L31,17l-.2-.4-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1V16l-.1.1-.1.1v.1l-.1.1-.2.6-.2.6L25.8,25a101.71,101.71,0,0,0-5.1,14.1,101.43,101.43,0,0,0-2.4,12,99.36,99.36,0,0,0-.4,12.8A78.74,78.74,0,0,0,19.7,79a85.43,85.43,0,0,0,4.6,15.2,129.28,129.28,0,0,0,5.8,12.5,57.61,57.61,0,0,0,6.3,9.2c2.4,2.9,4.7,5.6,7.1,8.3a55.27,55.27,0,0,0,6.7,6.4c2.2,1.6,3.3,2.5,3.4,2.5l.1.1.4.2.4.2.1.1.1.1.1.1.1.1.4.2.4.2.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1.1H52.9l-1.8-.2c-1.2-.1-4.8-.9-10.8-2.2a77.44,77.44,0,0,1-15.1-4.7l-6.1-2.8-.4-.2-.4-.2-.4-.2-.4-.2-.4-.1-.4-.1.7,8.3a89.63,89.63,0,0,0,2.8,16.3,97,97,0,0,0,6.5,16.9,80.61,80.61,0,0,0,9.8,15.5,115.51,115.51,0,0,0,10.1,11,93.14,93.14,0,0,0,9.8,7.8,77.93,77.93,0,0,0,13.8,7.3c5.8,2.5,9.3,3.9,10.6,4.3s2.2.6,2.8.8l.8.2.8.2.8.2.8.2.8.2-.1.1-.1.1-.1.1-.1.1-1,.2-1,.2-.8.2c-.5.1-1.9.4-4.3.8a119.52,119.52,0,0,1-12.8.8,97.8,97.8,0,0,1-13.5-.4l-4.3-.6-.6-.1-.6-.1.1.4.1.4.2.6.2.6,3.2,7.2a114.06,114.06,0,0,0,6.7,12.7,73.44,73.44,0,0,0,8.3,10.7,100.61,100.61,0,0,0,7.9,8,73.69,73.69,0,0,0,9.9,7.2A95.14,95.14,0,0,0,99,270.1a87.64,87.64,0,0,0,13.5,4.7,82.18,82.18,0,0,0,9.2,1.8c1.9.1,4,.3,6.1.4l3.1.2c117.9-117.9,82.9-167.7,82.9-167.7l-2.8-.1C209.5,109.3,206.1,108.9,201.1,108.5Z"
                    style="fill:#fff"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="main-menu-container--second">
        <router-link to="/mentions-legales" class="main-menu-little">Mentions légales</router-link>
        <router-link to="/politique-de-donnees-personnelles" class="main-menu-little">Politique de données
          personnelles
        </router-link>
        <router-link to="/conditions-generales" class="main-menu-little">Conditions générales</router-link>
        <router-link class="main-menu-little" to="/faq">F.A.Q</router-link>
        <router-link class="main-menu-little" to="/lexique">Lexique</router-link>
        <router-link class="main-menu-little" to="/contact">Contact</router-link>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MainMenu",
  mounted() {


    let links = document.querySelectorAll('.main-menu-link');

    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', () => {
        this.$root.menu = false;
      })
    }

    this.$anime({
      targets: document.querySelectorAll('.main-menu-logo'),
      opacity: [0, 1],
      translateY: [-100, 0],
    })

    this.$anime({
      targets: document.querySelectorAll('.main-menu-column:first-child .main-menu-link'),
      opacity: [0, 1],
      translateX: [-100, 0],
      delay: this.$anime.stagger(100, {start: 250})
    })

    this.$anime({
      targets: document.querySelectorAll('.main-menu-separation'),
      opacity: [0, 1],
      translateY: ['-100%', 0],
    })

    this.$anime({
      targets: document.querySelectorAll('.main-menu-column:last-child .main-menu-link'),
      opacity: [0, 1],
      translateX: ['100%', 0],
      easing: 'spring(1, 100, 80, 0)',
      delay: this.$anime.stagger(100, {start: 250})
    })

    this.$anime({
      delay: 250,
      targets: document.querySelectorAll('.main-menu-link--tw'),
      opacity: [0, 1],
      rotate: [30, 0],
      translateY: [30, 0],
      translateX: [-30, 0],
      easing: 'spring(1, 100, 80, 0)',
    })


    this.$anime({
      targets: document.querySelectorAll('.main-menu-little'),
      opacity: [0, 1],
      translateY: ['100%', 0],
      easing: 'spring(1, 100, 80, 0)',
      delay: this.$anime.stagger(100, {start: 250})
    })

  },
}
</script>


<style>
.main-menu-link--tw:hover #wing1,
.main-menu-link--tw:hover #wing2 {
  animation: flap .6s ease-out infinite alternate;
}

.main-menu-link--tw:hover path {
  fill: #1DA1F2 !important;
}

#wing1 {
  transform-origin: center;
}

#wing2 {
  transform-origin: center;
}

@keyframes flap {
  50% {
    transform: scaleX(-1) rotate(-45deg) translate(10px, -10px);
  }
}
</style>
