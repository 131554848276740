<template>

    <div class="offers-slider">

        <h3 class="homepage-title homepage-title--slider">
            <div>
                Découvrez nos offres

                <div class="offers-slider-buttons" v-if="$root.products.length">
                    <div class="offers-slider-button offers-slider-button--left" @click="previous"></div>
                    <div class="offers-slider-title">
                        {{ $root.products[index].title }}
                    </div>
                    <div class="offers-slider-button offers-slider-button--right" @click="next"></div>
                </div>

            </div>
        </h3>

        <div class="offers-slider-deco"></div>

        <div class="offers-slider-container">

            <div class="offers-slider-item" v-for="item in $root.products"
                 :style="{ backgroundImage: item.image_slider ? `url(/storage/${item.image_slider})` : 'none'}">

                <div class="container">

                    <h4 class="offers-slider-item--title" v-html="item.title">
                        LINK Vie
                    </h4>
                    <p class="offers-slider-item--body" v-html="item.slider_introduction">
                        Un contrat d'assurance vie simple, personnalisé et parmi les moins chers du marché
                    </p>
                    <div class="offers-slider-item-buttons">
                        <router-link to="/simulation" class="button offers-slider-item--button">
                            Je simule
                        </router-link>
                        <router-link :to="`/nos-offres/${item.slug}`" class="button offers-slider-item--button">
                            Je découvre
                        </router-link>
                    </div>
                </div>

            </div>

        </div>


    </div>

</template>

<script>
    export default {
        name: "OffersSlider",
        data() {
            return {
                flkty: null,
                index: 0,
            }
        },
        mounted() {

            setTimeout(() => {
                let elem = document.querySelector('.offers-slider-container')

                this.flkty = new this.$flickity(elem, {
                    cellAlign: 'left',
                    contain: true,
                    prevNextButtons: false,
                    pageDots: false,
                    wrapAround: true,
                    bgLazyLoad: true,
                    adaptiveHeight: true,
                });

                this.flkty.on('change',  index => {
                    this.index = index;
                });

            }, 1000)

        },
        methods: {
            previous() {
                this.flkty.previous()
            },
            next() {
                this.flkty.next()
            }
        }
    }
</script>
