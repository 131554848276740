import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const Homepage = require('./views/Homepage.vue').default;
const Posts = require('./views/Posts.vue').default;
const Single = require('./views/Single.vue').default;
const Perks = require('./views/Perks.vue').default;
const Offers = require('./views/Offers.vue').default;
const Product = require('./views/Product.vue').default;
const Simulation = require('./views/Simulation.vue').default;
const FAQ = require('./views/FAQ.vue').default;
const Lexique = require('./views/Lexique.vue').default;
const Page = require('./views/Page.vue').default;
const Contact = require('./views/Contact.vue').default;


let router = new Router({
    mode: 'history',
    base: process.env.NODE_ENV === 'preproduction' ? '/' : '',
    scrollBehavior(to, from, savedPosition) {

        if (to.hash)
            return {selector: to.hash}

        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'Homepage',
            component: Homepage,
        },
        {
            path: '/actualites',
            name: 'posts',
            component: Posts,
        },
        {
            path: '/actualites/:slug',
            name: 'single',
            component: Single,
        },
        {
            path: '/les-avantages',
            name: 'perks',
            component: Perks,
        },
        {
            path: '/nos-offres',
            name: 'offers',
            component: Offers,
        },
        {
            path: '/nos-offres/:slug',
            name: 'product',
            component: Product,
        },
        {
            path: '/simulation',
            name: 'simulation',
            component: Simulation,
        },
        {
            path: '/faq',
            name: 'faq',
            component: FAQ,
        },
        {
            path: '/lexique',
            name: 'lexique',
            component: Lexique,
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact,
        },
        {
            path: '/:slug',
            name: 'page',
            component: Page,
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (from.name === null) {
        next();
        return;
    }

    let loading = router.app.$refs.loading;
    router.app.menu = false;
    loading.load();

    setTimeout(() => {
        next();
    }, 250)
})

export default router;
