<template>

    <div class="testimonies">
        <div class="testimonies-item" v-for="item in testimonies">
            <div>
                <div class="testimonies-rating">
                    <svg class="star" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.57 226.04"
                         v-for="n in item.stars">
                        <path d="M159.12,67.25l69,12c8,1,11,11,6,17l-49,49,10,69c1,8-7,14-15,11l-61-31-63,31c-7,3-16-3-15-11l11-68-49-50c-6-6-3-16,6-17l68-12,32-62c4-7,15-7,18,0Z"
                              style="fill-rule:evenodd"/>
                    </svg>
                </div>
                <p class="testimonies-body" v-html="item.body"></p>
            </div>
            <div class="testimonies-info">
                <!--
                <div class="testimonies-picture">
                    <img v-if="item.thumbnails" :src="`${item.thumbnails}`" alt="">
                </div>
                -->
                <div class="testimonies-info-content">
                    <p class="testimonies-name" v-html="item.name"></p>
                    <p class="testimonies-job" v-html="item.job"></p>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "Testimonies",
        props: ['limit'],
        data() {
            return {
                testimonies: null,
            }
        },
        mounted() {

            axios.post('/api/testimonies', {limit: this.limit})
                .then(res => {
                    this.testimonies = res.data
                })
                .catch(err => {
                    console.log(err.response)
                })

        }
    }
</script>
